
@mixin rating(
    $name: 'rating'
) {
    .#{$name} {

        &__stars {
            background: $secondary-color-3;
            overflow: hidden;
            position: relative;
            width: 108px;
            height: 25px;
            z-index: 1;
        
            > svg {
                float: left;
                position: relative;
                left: -1px;
                top: -1px;
                width: 110px;
                height: 27px;
            }

            &--authed {
                cursor: pointer;
            }
        }

        &__stars-bg {
            background: $primary-color;
            height: inherit;
            position: absolute;
        }
    }
}