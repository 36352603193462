@mixin menubuilder(
    $name: 'menubuilder'
) {
    .#{$name} {
        
        &__form {
            align-items: center;
            display: flex;
            justify-content: center;
            position: relative;
            width: 100%;
        }
    }
}