
$search-row-height: 40px;
$search-divider-border-color: $tertiary-color;
$search-bar-width: 500px;
$search-bar-icon-width: 70px;
$search-bar-expand-width: 50px;

@mixin search(
    $name: 'search'
) {
    .#{$name} {
        position: relative;
        width: 100%;
        z-index: 1;
        
        @include breakpoint-min-lg {
            max-width: $search-bar-width;
        }

        &__advanced {
            @include boxshadow(2);

            background-color: $quaternary-color;
            border-bottom-left-radius: $border-radius-md; 
            border-bottom-right-radius: $border-radius-md; 
            display: none;
            flex-direction: column;
            padding: $gutter-small $gutter-small $gutter-xs $gutter-small;
            position: absolute;
            width: 100%;

            @include breakpoint-min-md {
                min-width: 400px;
            }
        }

        &__advanced--show {
            display: flex;
        }

        &__bar {
            background-color: #fff;
            display: flex;
            overflow: hidden;
            
            @include breakpoint-min-md {
                border-radius: $border-radius-sm;
            }

            > form {
                display: flex;
                height: $search-row-height + 6px;
                width: 100%;
                
                > .#{$name}__icon {
                    width: $search-bar-icon-width;
                    > svg {
                        fill: $font-color;
                    }
                }
            }

            > .#{$name}__expand,
            > .#{$name}__icon {
                border-radius: 0;
                background-color: transparent;
                flex-shrink: 0;
    
                &:hover,
                &:focus {
                    background-color: #eee;
                }
            }

            > .#{$name}__expand {
                width: 40px;

                &:before {
                    color: $font-color;
                    content: '\203A';
                    font-size: 30px;
                    transform: rotate(90deg);
                    position: absolute;
                    top: 4px;
                    left: 20px;
                }
            }
            
            > .#{$name}__expand--up {
                &:before {
                    transform: rotate(-90deg);
                    left: 12px;
                }
            }
            
            > .#{$name}__expand--hladv {
                background-color: $primary-color;

                &:before {
                    color: #fff;
                }
            }
        }

        &__field {
            border-radius: $border-radius-sm;
            border: $field-border;
            display: flex;
            position: relative;
        }
        
        &__input {
            display: flex;
            position: relative;
            width: 100%;

            > .#{$name}__icon {
                width: auto;
                > svg {
                    fill: $font-color;
                }
            }
        }

        &__bar--atcplt {
            flex-direction: column;
            position: relative;
            overflow: visible;
        }

        &__atcplt-item {
            color: #777;
            padding: $gutter-xs $gutter-small;

            &--focus {
                background-color: $tertiary-color-4;
                color: $font-color;
            }
        }

        &__autocomplete {
            @include boxshadow(1);

            background-color: #fff;
            border: $field-border;
            border-width: 1px;
            border-top: none;
            border-radius: $border-radius-sm;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px;
            padding: $gutter-xs 0;
            position: absolute;
            top: 47px;
            width: 100%;
            z-index: 3;
        }

        &__form {
            border-bottom: solid 1px $search-divider-border-color;
            display: flex;
            margin-bottom: $gutter-xs;
            padding-bottom: $gutter-xs;
        }

        &__ingredients {
            display: flex;
            flex-direction: column;
        }

        &__tags {
            border-left: solid 1px $search-divider-border-color;
            display: flex;
            flex-direction: column;
            margin-left: $gutter-xs;
            padding-left: $gutter-xs;
            max-width: 220px;
        }

        &__i {
            display: flex;
        }
        
        &__i--include {
            border-bottom: solid 1px $search-divider-border-color;
            margin-bottom: $gutter-xxs;
            padding-bottom: $gutter-xs;
        }

        &__t {
            width: 100%;
            
            &:nth-of-type(1) {
                margin-bottom: $gutter-small;                
            }
            
            textarea {
                width: 100%;
            }

            .field__label {
                font-size: 13px;
            }
        }
        
        &__wrap-i {
            align-items: center;
            display: flex;
            margin-top: $gutter-xxs;
            height: $search-row-height;

            .field {
                border-width: 1px;
            }
        }
        
        &__i-del {
            height: 32px;
            margin-left: $gutter-xs;
            padding: 3px 0 0 0;
            width: 32px;

            > svg {
                fill: #fff;
                height: 22px;
                width: 22px;
            }
        }

        &__i-add-wrap {
            height: inherit;
            display: flex;
            align-items: center;
            margin-left: $gutter-xs;
            position: relative;
            padding-right: 32px;
        }
        
        &__i-add {
            height: 32px;
            width: 32px;
            padding: 2px 0 0 0;
            position: absolute;
            bottom: 4px;

            > svg {
                fill: #fff;
                height: 28px;
                width: 28px;
            }
        }

        &__info {
            color: #fff;
            font-size: $font-size-sm;
            line-height: 16px;
            margin: $gutter-xxs 0 $gutter-xs 0;
        }

        &__form-btns {
            display: flex;
            justify-content: flex-end;
            
            > button {
                padding-top: 5px;
                padding-bottom: 5px;
            }

            > button:nth-child(1) {
                margin-right: auto;
            }
            
            > button:nth-child(2) {
                margin-right: $gutter-small;
            }
        }

        &__icon {
            display: flex;
            padding: 6px 12px 4px 12px;

            > svg {
                fill: #fff;
                height: 36px;
                width: 36px;
            }
        }
    }
}