@mixin admin(
    $name: 'admin'
) {
    .#{$name} {
        display: flex;
        width: 100%;

        &__nav {
            display: flex;
            flex-direction: column;
            margin-right: $gutter * 2;

            a[role='button'] {
                margin-bottom: $gutter-xs;
            }
        }
        
        &__view {
            display: flex;
            flex-direction: column;
            width: 100%;
            
            > h1 {
                margin-top: 0;
            }
        }
    }

    .itemedit {
        display: flex;
        width: 100%;
        margin: $gutter 0 $gutter-large 0;

        > .search__field {
            max-width: 300px;
        }
    }
}