@mixin blog(
    $name: 'blog'
) {
    .#{$name} {
        display: flex;
        flex-direction: column;

        > h1 {
            font-weight: 600;
        }
        
        &__body {
            > h2,
            > h3,
            > h4 {
                font-weight: 600;
            }
        }

        &__summary {
            @include boxshadow(1);
            border-radius: $border-radius-sm;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            width: 100%;
            max-width: 300px;
            margin-bottom: $gutter;

            @include breakpoint-min-sm {
                flex-direction: row;
                max-width: none;

                > img.blog__s-img {
                    max-width: 200px;
                }
            }
            
        }

        &__s-detail {
            // padding: 0 $gutter-small $gutter-small $gutter-small;
            padding: $gutter-small;
            width: 100%;
        }

        &__s-date {
            color: $tertiary-color;
            font-size: $font-size-md;
            
            @include breakpoint-min-sm {
                margin-left: $gutter;
            }
        }

        &__s-meta {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include breakpoint-min-sm {
                justify-content: start;
            }
        }

        &__s-title {
            margin-bottom: $gutter-medium;
        }

        &__s-desc {
            max-height: 40px;
        }

        &__s-img {
            align-self: center;
            max-width: 400px;
            min-height: 200px;
        }

        &__p-img-wrap {
            display: flex;
            width: 100%;
            justify-content: center;

            > .#{$name}__p-img {
                max-width: 800px;
                margin: $gutter 0;
            }
        }

        &__summary-hp-detail {
            @include boxshadow(1);

            border-radius: $border-radius-sm;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            background-color: #fff;
            background-position: 50% 50%;
            background-size: cover;
            padding-bottom: 100%;
            width: 100%;
            position: relative;
        }

        &__summary-hp-title {
            background: rgba($quaternary-color, .8);
            bottom: 0;
            color: #fff;
            display: flex;
            flex-direction: column;
            font-size: $font-size-lg;
            min-height: 60px;
            justify-content: center;
            padding: 16px;
            position: absolute;
            width: 100%;
        }
    }

    .#{$name}__summary-hp,
    .home__flavors {
        width: 100%;
        height: 100%;
        margin: $gutter-xs 0;

        @include breakpoint-min-sm {
            min-width: 50%;
            width: 50%;
            margin: $gutter-small 0;
            padding: 0 $gutter-small;
        }

        @include breakpoint-min-lg {
            min-width: 33%;
            width: 33%;
        }
    }

    .home__flavors {
        height: 430px;
        overflow: hidden;

        > h3 {
            margin-top: 0;
        }

        > .flavor__sample {
            justify-content: flex-start;
            padding-top: 8px;
        }

        @include breakpoint-min-sm {
            height: 375px;
        }

        @include breakpoint-min-md {
            height: 380px;
        }

        @include breakpoint-min-xl {
            height: 430px;
        }
    }
}
