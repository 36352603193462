
@mixin viewblocker(
    $name: 'viewblocker'
) {
    .#{$name} {
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 100%;
        justify-content: center;
        position: absolute;
        width: 100%;
        
        &__blocker {
            @include blockingAppBackground();
            bottom: 0;
            left: 0;
            position: fixed;
            top: 0;
            width: 100%;            
            z-index: 5;
        }

        &__children {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: center;
            position: relative;
            width: 100%;
            z-index: 6;
        }
    }

    .#{$name}.appwaiting {
        z-index: 10;
    }
}