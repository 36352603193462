
@mixin auth(
    $name: 'auth'
) {
    .#{$name} {

        // si = sign in
        &-si {
            align-items: center;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        &-si--inline {

            h2 {
                font-size: 18px;
                margin-bottom: 0;

                span.logo {
                    font-size: 20px;
                }
            }
        }

        &-si__forgotpass {
            margin: $gutter-medium 0;
        }

        &-si__title {
            margin-bottom: 0;
        }

        &-si__sucta {
            margin-top: $gutter-small;
        }
    }
}