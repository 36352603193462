
.input-required {
    color: $error-color;
    position: relative;
    left: 2px;
}

@mixin form(
    $name: 'form'
) {
    .#{$name} {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        max-width: 280px;
        position: relative;
        width: 100%;

        &__row,
        &__field,
        &__group {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            position: relative;
            width: 100%;
        }

        &__row {
            margin-top: $gutter-top;
            justify-content: flex-start;
        }

        &__group {
            flex-direction: column;
        }
        
        // nested rows
        &__row .#{$name}__row {
            margin-right: $gutter-small * -1;
            margin-left: $gutter-small * -1;
            margin-top: 0;
            justify-content: space-between;
        }
        
        &__row .#{$name}__row--flexstart {
            margin-right: 0;
            margin-left: 0;
            flex-direction: column;
            align-items: flex-start;

            @include breakpoint-min-sm {
                align-items: center;
                flex-direction: row;
                justify-content: flex-start;
            }
        }

        // nested rows
        &__row .#{$name}__row > .#{$name}__field {
            margin-right: $gutter-small;
            margin-left: $gutter-xs;
        }

        &__info {
            padding-right: $gutter-medium;
            width: 100%;
            max-width: 175px;

            @include breakpoint-min-sm {
                max-width: 200px;
            }
    
            @include breakpoint-min-md {
                max-width: 250px;
            }
    
            @include breakpoint-min-lg {
                max-width: 300px;
            }
        }

        &__infotext {
            margin: 0;
        }

        &__separater {
            border-top: solid 1px $secondary-color-2;
            height: 0;
            margin-top: $gutter-top;
            width: 100%;
        }

        &--error {
            color: $error-color;
            margin: 0;
            margin-top: $gutter;
            text-align: center;
            width: 100%;
        }

        &--md {
            max-width: 380px;
        }

        &--xl {
            max-width: 100%;
        }

        &__btns {
            align-items: center;
            display: flex;
            justify-content: center;
            margin-top: $gutter-medium;
            width: 100%;
        }

        &__btngroup {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            width: 50%;
        }
        
        &__btngroup:last-child {
            justify-content: flex-end;
        }
        
        &__btngroup:only-child {
            justify-content: center;
            width: 100%;
        }

        &__btnprescript {
            font-size: $font-size-md;
            margin: 0;
            margin-top: $gutter-medium;
            text-align: center;
        }

        @include breakpoint-min-sm {
            &--horizontal {
                align-items: flex-end;
                flex-direction: row;
                max-width: none;
                justify-content: flex-start;
                position: relative;
                width: 100%;
                height: 72px;
                top: -1 * $gutter;

                .#{$name}__row {
                    margin-right: $gutter-small;
                    width: auto;
                }

                .#{$name}__btns {
                    justify-content: flex-start;
                    height: 100%;
                    margin: 0;
                    position: relative;
                    bottom: -12px;
                    width: auto;
                }

                .#{$name}__btngroup {
                    margin-right: $gutter-small;
                    position: relative;

                    > .btn__lg {
                        font-size: $font-size;
                        padding: $button-padding;
                    }
                }

                .#{$name}__row,
                .#{$name}__field,
                .#{$name}__group {
                    width: 100%;
                }
            }
        }
    }

    .#{$name}.gutters-large > .form__row {
        margin-top: $gutter-top-large;
    }

    .#{$name}.gutters-small > .form__row {
        margin-top: $gutter-top-small;
    }

    .#{$name}--xl {
        .#{$name}.gutters-large > .form__row {
            margin-top: $gutter-large;
        }
    }
}