
@mixin flavor(
    $name: 'flavor'
) {
    .#{$name} {
        &__h1 {
            margin-bottom: $gutter;
        }

        &__feed,
        &__searchmeta {
            max-width: $breakpoint-sm-min;
            width: 100%;
        }

        &__input {
            @include boxshadow(1);
            
            width: 100%;
        }

        &__ingr-meta {
            display: flex;
            flex-direction: column;

            @include breakpoint-min-sm {
                flex-direction: row;
            }
        }

        &__tags {
            margin-bottom: $gutter-small;

            @include breakpoint-min-sm {
                margin-top: $gutter-large;
                margin-right: $gutter;
                padding-top: $gutter-xxs;
            }

            > .chip {
                margin-right: $gutter-xxs;
                margin-bottom: $gutter-xxs;
                @include breakpoint-min-sm {
                    margin-right: 0;
                }
            }
        }

        &__description {
            @include boxshadow(1);
            border-radius: $border-radius-sm;
            //margin-bottom: $gutter-xl;
            margin-bottom: $gutter;

            > .expander__wrap {
                max-height: 108px;
            }

            p {
                margin: $gutter-xs;
            }
        }

        &__dcrp-src {
            display: flex;
            align-items: flex-start;
            bottom: 0;
            right: 0;
            position: absolute;
            padding-left: 30px;
            height: 25px;
            background-image: linear-gradient(
                            to left,
                            rgb(255, 255, 255) 80%,
                            rgba(255, 255, 255, .2)
            );

            > a {
                margin-right: $gutter-small;
            }
        }

        &__pairing {
            align-items: center;
            display: flex;
            justify-content: space-between;
            position: relative;
            width: 100%;

            .search__icon {
                padding: 10px;
                width: 50px;
                height: 50px;

                svg {
                    fill: $tertiary-color;
                }
            }
        }

        &__p-item {
            color: $font-color;
            position: relative;
            padding: $gutter-medium $gutter-xs + 7px $gutter-medium $gutter-xs;

            &:after {
                content: '\26B2';
                position: relative;
                left: 7px;
                top: 1px;
                display: inline-block;
                transform: rotate(-45deg);
            }

            &:hover {
                background-color: #eee;
            }
        }

        &__p-item {
            font-size: 17px;
        }

        &__p-item-rs {
            width: 24px;
            height: 24px;
            position: relative;
            top: 2px;
            
            > svg {
                fill: $tertiary-color;
                position: relative;
                width: inherit;
                height: inherit;
            }
        }

        &__p-affinity-wrap {
            background-color: $tertiary-color-5;
            border-radius: 10px;
            bottom: 0;
            position: absolute;
            width: 100%;
            height: 7px;
            overflow: hidden;
        }

        &__p-affinity {
            height: 7px;
        }

        &__rs-title {
            margin-top: 0;
            margin-bottom: $gutter-xl;
            width: 100%;
        }

        &__aff-sl {
            display: flex;
            font-size: $font-size-md;
            justify-content: space-between;
            margin-top: 6px;
            padding: 0 2px 0 5px;
            width: 100%;

            > div {
                color: $tertiary-color-2;
            }
        }

        &__aff-2 {
            background-color: #7ddcb9;

            &:hover {
                background-color: scale-color(#7ddcb9, $lightness: 25%);
            }

            &:focus {
                background-color: #7ddcb9;
            }
        }

        &__aff-3 {
            background-color: #3cca96;

            &:hover {
                background-color: scale-color(#3cca96, $lightness: 25%);
            }

            &:focus {
                background-color: #3cca96;
            }
        }

        &__aff-4 {
            background-color: #10ae74;

            &:hover {
                background-color: scale-color(#10ae74, $lightness: 25%);
            }

            &:focus {
                background-color: #10ae74;
            }
        }

        &__aff-5 {
            background-color: #0b7a51;

            &:hover {
                background-color: scale-color(#0b7a51, $lightness: 25%);
            }

            &:focus {
                background-color: #0b7a51;
            }
        }

        &__sample {
            display: flex;
            flex-direction: row;
            max-width: $breakpoint-sm-min;
            flex-wrap: wrap;
            padding-top: $gutter;
            justify-content: space-between;

            > a {
                margin: 0 $gutter-xs/2 $gutter-small $gutter-xs/2;
            }
        }

        &__affin-wrap {
            margin: $gutter-small 0 $gutter-large 0;
            position: relative;
            width: 100%;
            display: flex;
            flex-direction: column;

            > h3 {
                margin-top: 0px;
            }
        }

        &__affin-row {
            display: flex;
            flex-direction: row;

            margin-top: $gutter-small;
        }

        &__affin {
            margin-right: $gutter-xs;
            position: relative;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            max-width: 25%;

            &:before {
                content: '&';
                font-size: $font-size-sm;
                margin-right: $gutter-xs;
                position: relative;
            }
        }

        &__affin {
            &:first-of-type {
                &:before {
                    content: none;
                    margin-left: 0;
                }   
            }
        }

        &__affin-icon {
            height: 20px;
            width: 20px;
            position: relative;
            top: 0px;
        }

        &__lp {
            @include breakpoint-min-sm {
                max-width: $breakpoint-md-min;
            }

            &-section,
            .parsed-content {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 100%;
                align-items: center;
            }

            img {
                width: 100%;
                max-width: 300px;
            }

            h3 {
                font-size: $font-size-xl;
                font-weight: 600;
            }

            h1, h2, h3, img {
                margin: $gutter-medium 0;
            }

            p {
                margin: $gutter-small;
            }

            &-section {
                @include breakpoint-min-md {
                    flex-direction: row;

                    > img {
                        margin-right: $gutter-medium;
                    }
                }
            }

            &-section-2 {
                @include breakpoint-min-md {
                    > img {
                        margin-left: $gutter-medium;
                        min-width: 250px;
                    }
                }
            }

            &-search {
                padding-top: $gutter-medium;
            }
        }

        &__lp-img-rnd {
            @include boxshadow(2);
            border-radius: 10px;
        }

        &__lp-head {
            display: flex;
            flex-direction: column;
            align-items: center;

            @include breakpoint-min-sm {
                flex-direction: row;
            }

            > h1 {
                font-size: $font-size-xxl;
                font-weight: 600;
            }

            > img {
                width: 100%;
                @include breakpoint-min-sm {
                    max-width: 200px;
                }
            }
        }
    }

    .form.flavor__search {
        height: auto;
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
        max-width: $breakpoint-sm-min;
        top: 0;
        justify-content: space-between;

        > .form__row:nth-child(1) {
            order: 1;
        }
        
        > .form__row:nth-child(2) {
            order: 3;
        }
        
        > .form__row:nth-child(3) {
            order: 4;
        }

        > .form__row {
            margin: 0;
            margin-bottom: $gutter-medium;
        }

        > .form__row.flavor__toggle {
            margin-right: $gutter;
        }

        @include breakpoint-min-sm {
            min-width: 400px;
        }

        .form__row {
            width: auto;
        }

        .form__row.flavor__order {
            min-width: 150px;
        }

        .form__row.flavor__input {
            width: 100%;
        }
    }
}