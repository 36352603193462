
@mixin flex(
    $name: 'flex'
) {
    .#{$name} {
        
        &__column {
            display: flex;
            flex-flow: column;
        }
    }
}