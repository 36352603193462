
$font-color: #444;
$font-size: 16px;
$font-size-sm: 12px;
$font-size-md: 14px;
$font-size-lg: 18px;
$font-size-xl: 24px;
$font-size-xxl: 32px;

$primary-color: #F7941E;
$primary-color-2: scale-color($primary-color, $lightness: 10%);
$primary-color-3: scale-color($primary-color, $lightness: 20%);
$primary-color-4: scale-color($primary-color, $lightness: 30%);
$primary-color-5: scale-color($primary-color, $lightness: 40%);
$primary-color-dark-1: scale-color($primary-color, $lightness: -10%);
$primary-color-dark-2: scale-color($primary-color, $lightness: -20%);
$secondary-color: #00AAA6;
$secondary-color-2: #68bab8;
$secondary-color-3: #9acdcc;
$secondary-color-4: #cbe3e2;
$secondary-color-4-10: lighten($secondary-color-4, 10%);
$tertiary-color: #9F8B8A;
$tertiary-color-2: #b3a2a1;
$tertiary-color-3: #c8bcbb;
$tertiary-color-4: #e0dad8;
$tertiary-color-5: scale-color($tertiary-color-4, $lightness: 20%);
$quaternary-color: #565353;
$quaternary-color-2: scale-color($quaternary-color, $lightness: 10%);
$quaternary-color-3: scale-color($quaternary-color, $lightness: 20%);
$quaternary-dark-1: scale-color($quaternary-color, $lightness: -10%);
$error-color: #E82711;
$error-color-2: scale-color($error-color, $lightness: 10%);
$green: #11BF7F;
$link-color: $primary-color-dark-2;

$border-radius-sm: 5px;
$border-radius-md: 8px;
$border-radius-lg: 10px;
$border-radius-xl: 16px;

$transition-default: 0.1s;
$transition-expand: 0.5s;

$gutter: 24px;
$gutter-xxs: 4px;
$gutter-xs: 8px;
$gutter-small: 16px;
$gutter-medium: 24px;
$gutter-large: 32px;
$gutter-xl: 40px;
$gutter-p: 16px;
$gutter-sides: $gutter;
$gutter-top: $gutter;
$gutter-top-small: $gutter-medium;
$gutter-top-large: $gutter-large;

$button-padding: 7px 20px 9px 20px;

$form-height-inline-element: 48px;
$form-height-textarea: 200px;
$form-height-textarea-small: 75px;

// Small tablets and large smartphones (landscape view)
$breakpoint-sm-min: 576px;

// Small tablets (portrait view)
$breakpoint-md-min: 768px;

// Tablets and small desktops
$breakpoint-lg-min: 992px;

// Large tablets and desktops
$breakpoint-xl-min: 1200px;

$topnav-height: 75px;
$topnav-link-height-sm: 50px;

$avatar-width: 50px;
$avatar-width-sm: 40px;

$max-width-content: 1024px;
$max-width-content-body: $breakpoint-md-min;