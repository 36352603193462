
.logo {
    color: $primary-color;
    font-family: Lobster;
    font-size: 30px;
    font-weight: normal;
}


#app-preload {
    align-items: center;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;

    > h1 {
        font-size: 18px;
        font-weight: normal;
    }

    > h1,
    > .preloader-wrapper {
        margin-top: $gutter-large;
    }

    .footer {
        position: absolute;
    }
}