@mixin share(
    $name: 'share'
) {
    .#{$name} {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        &__icons {
            display: flex;
            justify-items: center;
            margin: $gutter 0;
        }

        &__copy {
            display: flex;
            justify-content: center;
            margin-top: $gutter-xs;
        }
        
        &__site {
            width: 36px;
            height: 36px;
            padding: 0;
            margin-right: $gutter-small;

            &:nth-last-child(1) {
                margin-right: 0;
            }

            > svg {
                width: inherit;
                height: inherit;
            }
        }
    }
}