@mixin confirm(
    $name: 'confirm'
) {
    .#{$name} {
        display: flex;
        flex-direction: column;
        
        &__action {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin: $gutter-p 0;

            > button {
                margin: 0 $gutter-p;
                min-width: 75px;
            }
        }
    }
}