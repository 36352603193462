html, body {
    height: 100%
}

html {
    box-sizing: border-box;
}
*,
*:before,
*:after {
    box-sizing: inherit;
    color: $font-color;
    margin-block-end: 0;
}

body {
    margin: 0;
    padding: 0;
    font-family: "open sans", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}

a {
    color: $link-color;
    cursor: pointer;
    text-decoration: none;
}

p,
li {
    line-height: 24px;
}

p {
    margin-bottom: $gutter-p;
    margin-top: $gutter-p;
}

#root {
    position: relative;
    width: 100%;
    height: 100%;
}

h1, h2, h3, h4, h5, h6 {
    line-height: normal;
    font-weight: 500;
}

h1 {
    font-size: $font-size-xl;
}

h2, h3 {
    font-size: $font-size-lg;
}

h4, h5, h6 {
    font-size: $font-size;
}

.color__secondary {
    color: $secondary-color;
}

.bold {
    font-weight: 700;
}

textarea {
    line-height: 26px;
    padding: 10px;
    width: 100%;
    font-size: 16px; // needs to be 16 on mobile to prevent zooming
}

div.app-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    height: 100%;
    position: relative;
    width: 100%;
}

div.app-view {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    align-items: center;
    overflow: hidden;
    padding: $gutter * 2 $gutter;
    width: 100%;
    min-height: 800px;

    @media print {
        padding-top: 0;
        min-height: 0;
    }
}

div.view-content {
    @include boxshadow(1);

    align-items: center;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding-bottom: $gutter;
    padding-left: $gutter-sides;
    padding-right: $gutter-sides;
    width: 100%;

    > h4 {
        width: 100%;
    }
}

div.app-loading {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
}

div.img--round {
    background-position: center top;
    background-size: cover;
    border-radius: 50%;
    height: 50px;
    width: 50px;
}

.error-color {
    color: $error-color
}
