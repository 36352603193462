
@mixin expander(
    $name: 'expander'
) {
    .#{$name} {
        position: relative;
        margin-top: $gutter-large;

        &__wrap {
            max-height: 36px;
            overflow: hidden;
            position: relative; 
            transition-property: max-height;
        }

        &__more {
            background-color: transparent;
            box-shadow: none;
            color: $secondary-color;
            position: absolute;
            right: 25px;
            top: -30px;
            z-index: 1;

            &:hover,
            &:focus {
                background-color: transparent;
                box-shadow: none;
                color: $secondary-color-3;

                &:before {
                    color: $secondary-color-3;
                }
            }

            &:before {
                color: $secondary-color;
                content: '\203A';
                font-size: 24px;
                font-weight: 500;
                left: 0px;
                position: absolute;
                top: .5px;
                transform: rotate(90deg);
            }
        }

        &__more.rotate-caret {
            &:before {
                left: -6px;
                transform: rotate(-90deg);
            }
        }
    }
}