.app-loading {
    position: relative;
        
    .app-loading__reveal {
        animation: keyFramesFadeOutOpacity 3s;
        opacity: 0;
        background-color: #fff;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
}

.circle{
    border-radius:50%
}

.preloader-wrapper{
    display:inline-block;
    position:relative;
    width:50px;
    height:50px
}
.preloader-wrapper.small{
    width:36px;
    height:36px
}
.preloader-wrapper.big{
    width:64px;
    height:64px
}
.preloader-wrapper.active{
    -webkit-animation:container-rotate 1568ms linear infinite;
    animation:container-rotate 1568ms linear infinite
}
@-webkit-keyframes container-rotate{
    to{
        -webkit-transform:rotate(360deg)
    }
}
@keyframes container-rotate{
    to{
        -webkit-transform:rotate(360deg);
        transform:rotate(360deg)
    }
}

.spinner-layer {
    position:absolute;
    width:100%;
    height:100%;
    opacity:0;
    border-color: $primary-color;
}
.spinner-color,
.spinner-color-only{
    border-color: $primary-color;
}

.white > .spinner-layer {
    border-color: #fff;
}
.white > .spinner-color,
.white > .spinner-color-only {
    border-color: #fff;
}


.active .spinner-layer.spinner-color{
    -webkit-animation:fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,color-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation:fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both,color-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}

.active .spinner-layer,
.active .spinner-layer.spinner-color-only {
    opacity:1;
    -webkit-animation:fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation:fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}
@-webkit-keyframes fill-unfill-rotate{
    12.5%{
        -webkit-transform:rotate(135deg)
    }
    25%{
        -webkit-transform:rotate(270deg)
    }
    37.5%{
        -webkit-transform:rotate(405deg)
    }
    50%{
        -webkit-transform:rotate(540deg)
    }
    62.5%{
        -webkit-transform:rotate(675deg)
    }
    75%{
        -webkit-transform:rotate(810deg)
    }
    87.5%{
        -webkit-transform:rotate(945deg)
    }
    to{
        -webkit-transform:rotate(1080deg)
    }
}
@keyframes fill-unfill-rotate{
    12.5%{
        -webkit-transform:rotate(135deg);
        transform:rotate(135deg)
    }
    25%{
        -webkit-transform:rotate(270deg);
        transform:rotate(270deg)
    }
    37.5%{
        -webkit-transform:rotate(405deg);
        transform:rotate(405deg)
    }
    50%{
        -webkit-transform:rotate(540deg);
        transform:rotate(540deg)
    }
    62.5%{
        -webkit-transform:rotate(675deg);
        transform:rotate(675deg)
    }
    75%{
        -webkit-transform:rotate(810deg);
        transform:rotate(810deg)
    }
    87.5%{
        -webkit-transform:rotate(945deg);
        transform:rotate(945deg)
    }
    to{
        -webkit-transform:rotate(1080deg);
        transform:rotate(1080deg)
    }
}
@-webkit-keyframes color-fade-in-out{
    from{
        opacity:1
    }
    25%{
        opacity:1
    }
    26%{
        opacity:0
    }
    89%{
        opacity:0
    }
    90%{
        opacity:1
    }
    100%{
        opacity:1
    }
}
@keyframes color-fade-in-out{
    from{
        opacity:1
    }
    25%{
        opacity:1
    }
    26%{
        opacity:0
    }
    89%{
        opacity:0
    }
    90%{
        opacity:1
    }
    100%{
        opacity:1
    }
}

.gap-patch{
    position:absolute;
    top:0;
    left:45%;
    width:10%;
    height:100%;
    overflow:hidden;
    border-color:inherit
}
.gap-patch .circle{
    width:1000%;
    left:-450%
}
.circle-clipper{
    display:inline-block;
    position:relative;
    width:50%;
    height:100%;
    overflow:hidden;
    border-color:inherit
}
.circle-clipper .circle{
    width:200%;
    height:100%;
    border-width:3px;
    border-style:solid;
    border-color:inherit;
    border-bottom-color:transparent !important;
    border-radius:50%;
    -webkit-animation:none;
    animation:none;
    position:absolute;
    top:0;
    right:0;
    bottom:0
}
.circle-clipper.left .circle{
    left:0;
    border-right-color:transparent !important;
    -webkit-transform:rotate(129deg);
    transform:rotate(129deg)
}
.circle-clipper.right .circle{
    left:-100%;
    border-left-color:transparent !important;
    -webkit-transform:rotate(-129deg);
    transform:rotate(-129deg)
}
.active .circle-clipper.left .circle{
    -webkit-animation:left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation:left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}
.active .circle-clipper.right .circle{
    -webkit-animation:right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
    animation:right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both
}
@-webkit-keyframes left-spin{
    from{
        -webkit-transform:rotate(130deg)
    }
    50%{
        -webkit-transform:rotate(-5deg)
    }
    to{
        -webkit-transform:rotate(130deg)
    }
}
@keyframes left-spin{
    from{
        -webkit-transform:rotate(130deg);
        transform:rotate(130deg)
    }
    50%{
        -webkit-transform:rotate(-5deg);
        transform:rotate(-5deg)
    }
    to{
        -webkit-transform:rotate(130deg);
        transform:rotate(130deg)
    }
}
@-webkit-keyframes right-spin{
    from{
        -webkit-transform:rotate(-130deg)
    }
    50%{
        -webkit-transform:rotate(5deg)
    }
    to{
        -webkit-transform:rotate(-130deg)
    }
}
@keyframes right-spin{
    from{
        -webkit-transform:rotate(-130deg);
        transform:rotate(-130deg)
    }
    50%{
        -webkit-transform:rotate(5deg);
        transform:rotate(5deg)
    }
    to{
        -webkit-transform:rotate(-130deg);
        transform:rotate(-130deg)
    }
}