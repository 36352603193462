
@mixin fieldselect(
    $name: 'select'
) {
    .#{$name} {
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        background-color: #fff;
        border-radius: $border-radius-sm;
        border: none;
        cursor: pointer;
        display: block;
        font-size: 16px; // needs to be 16 on mobile to prevent zooming
        line-height: 1.3;
        margin: 0;
        max-width: 100%; 
        padding: .6em 1.4em .5em .8em;
        position: relative;
        width: 100%;
        
        &::-ms-expand {
            display: none;
        }

        option {
            font-weight:normal;
        }

        &:focus {
            outline: none;
        }

        &__wrap {
            max-width: 200px;
        }    

        &__wrap::after {
            content: '\203A';
            color: $secondary-color-2;
            pointer-events: none;
            font-size: 26px;
            font-weight: 600;
            position: absolute;
            transform: rotate(90deg);
            top: 10px;
            right: 13px;
            z-index: 1;
        }
        
        &__wrap--nocaret::after {
            content: '';
            color: none;
            pointer-events: none;
            font-size: 26px;
            font-weight: 600;
            position: relative;
            transform: none;
            top: 0;
            right: 0;
            z-index: 0;
        }
    }

    .field__selecttime {
        .#{$name} {
            padding-left: 2px;
            padding-right: 2px;
        }
    }

    .selecttime__row {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-right: $gutter-medium;
        position: relative;
    }

    .form__row--flexstart {
        .selecttime__row {
            margin-bottom: $gutter;

            @include breakpoint-min-sm {
                margin-bottom: 0;
            }
        }
    }
}