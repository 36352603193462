@mixin card(
    $name: 'card'
) {
    .#{$name} {
        margin: $gutter-xs 0;
        padding: 0 $gutter-xs;
        min-width: 100%;
        width: 100%;

        @include breakpoint-min-sm {
            min-width: 50%;
            width: 50%;
        }

        @include breakpoint-min-md {
            min-width: 33%;
            width: 33%;
        }

        @include breakpoint-min-lg {
            min-width: 25%;
            width: 25%;
        }

        @include breakpoint-min-xl {
            min-width: 20%;
            width: 20%;
        }

        &__view {
            @include boxshadow(1);

            border-radius: $border-radius-sm;
            display: flex;
            flex-direction: column;
            overflow: hidden;
            position: relative;
            width: 100%;
        }

        &__img {
            background-color: #fff;
            background-position: 50% 50%;
            background-size: cover;
            padding-bottom: 100%;
        }

        &__info {
            padding: $gutter-small $gutter-xs;
            width: 100%;

            > h3 {
                margin-top: 0;
                margin-bottom: $gutter-small;
            }
        }

        &__title {
            font-size: 14px;
            font-weight: 600;
            margin: $gutter-xs 0 $gutter-medium 0;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            width: 100%;

            &:hover {
                overflow: visible;
                white-space: normal;
            }
        }

        &__user {
            align-items: center;
            display: flex;
            width: 100%;
        }

        &__avatar {
            height: 30px;
            width: 30px;
        }

        &__username {
            font-size: $font-size-sm;
            line-height: $font-size-sm + 4px;
            margin: 0;
            overflow: hidden;
            padding: 0 10px;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &__top {
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            z-index: 1;           
        }

        &__top--flexend {
            justify-content: flex-end;
        }
    }

    // .feed--hidedanglers {
    //     @include breakpoint-sm-to-md {
    //         > .card:last-child:not(:nth-child(2n+2)) {
    //             display: none;
    //         }
    //     }
        
    //     @include breakpoint-md-to-lg {
    //         > .card:last-child:not(:nth-child(3n+3)),
    //         > .card:nth-last-child(2):not(:nth-child(3n+3)):not(:nth-child(3n+2)) {
    //             display: none;
    //         }
    //     }

    //     @include breakpoint-lg-to-xl {
    //         > .card:last-child:not(:nth-child(4n+4)),
    //         > .card:nth-last-child(2):not(:nth-child(4n+4)):not(:nth-child(4n+3)),
    //         > .card:nth-last-child(3):not(:nth-child(4n+4)):not(:nth-child(4n+3)):not(:nth-child(4n+2)) {
    //             display: none;
    //         }
    //     }

    //     @include breakpoint-min-xl {
    //         > .card:last-child:not(:nth-child(5n+5)),
    //         > .card:nth-last-child(2):not(:nth-child(5n+5)):not(:nth-child(5n+4)),
    //         > .card:nth-last-child(3):not(:nth-child(5n+5)):not(:nth-child(5n+4)):not(:nth-child(5n+3)),
    //         > .card:nth-last-child(4):not(:nth-child(5n+5)):not(:nth-child(5n+4)):not(:nth-child(5n+3)):not(:nth-child(5n+2)) {
    //             display: none;
    //         }
    //     }
    // }
}