@mixin recipe(
    $name: 'recipe'
) {
    .#{$name} {
        &__info {
            display: flex;
            justify-content: space-between;
            width: 100%;
        }

        @media print {
            max-width: 2000px;
            padding-top: 0;
        }

        &__printcta {
            display: none;
            font-size: $font-size-lg;
            font-weight: bold;
            margin-bottom: $gutter-small;

            @media print {
                display: block;
            }
        }

        &__ingredients {
            margin-top: $gutter-large;

            > h3 {
                margin-top: 0;
            }
        }
        
        &__preparation {
            > h3 {
                margin-bottom: $gutter-small;
            }
        }

        &__name {
            margin-top: 0;
            margin-bottom: $gutter-small;

            @media print {
                font-size: $font-size-lg;
                margin-top: 0;
            }
        }

        &__visual {
            display: none;
            flex-grow: 1;
            max-width: 400px;
            width: 100%;

            > .#{$name}__img {
                @include boxshadow(1);
                border-radius: $border-radius-md;
                width: 100%;
            }
        }
        
        &__meta {
            flex-grow: 2;
            
            > .#{$name}__img {
                margin-top: $gutter-small;
                width: 100%;
            }
        }

        &__tags {
            margin-bottom: $gutter;

            @media print {
                display: none;
            }
        }

        &__actions {
            display: flex;
            flex-wrap: wrap;

            > button,
            a[role=button] {
                margin: 0 $gutter-xs $gutter-xs 0;
                flex-shrink: 0;
            }
        }

        &__feedback {
            align-items: center;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
        }

        &__ratingtext {
            font-size: $font-size-md;
            margin-right: $gutter-xs;
        }

        &__rating {
            background: $secondary-color-3;
            overflow: hidden;
            position: relative;
            width: 108px;
            height: 25px;
        
            > svg {
                float: left;
                position: relative;
                left: -1px;
                top: -1px;
                width: 110px;
                height: 27px;
            }
        }

        &__rating-bg {
            background: $primary-color;
            height: inherit;
            position: absolute;
        }

        &__attrs {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            position: relative;
        }

        &__print {
            margin-top: $gutter;
        }

        &__time {
            display: flex;
            margin-top: $gutter-medium;

            > p {
                margin: 0 $gutter 0 0;

                > span {
                    font-weight: 700;
                }
            }
        }

        &__srvsys {
            display: flex;
            flex-direction: row;
        }

        &__scale {
            align-items: center;
            display: flex;
            margin-top: $gutter-medium;
            margin-right: $gutter-small;
            width: 110px;
        }

        &__steps {
            list-style: none;
            counter-reset: recipe-step-counter;
            display: flex;
            flex-direction: column;
            margin: 0;
            padding: 0;

            > li {
                counter-increment: recipe-step-counter;
                margin-bottom: $gutter-small;
                position: relative;
            }

            > li::before {
                color: $primary-color;
                content: counter(recipe-step-counter);
                font-family: Lobster, serif;
                font-size: $font-size-xl;
                margin-right: $gutter-xs;
                position: relative;
                top: 3px;
            }
        }

        &__preparation,
        &__notes {
            max-width: 560px;
        }

        &__comments {
            margin-top: $gutter-large;
            position: relative;

            @media print {
                display: none;
            }
        }

        &--embed {
            .#{$name}__name {
                font-size: $font-size-lg;
                margin-bottom: 0;
            }

            .#{$name}__ingredients {
                > p {
                    margin: $gutter-xs 0;
                }
            }

            .#{$name}__steps {
                > li::before {
                    color: $font-color;
                }
            }
        }

        &__embed {
            > hr {
                border: none;
                border-top: solid 1px #ccc;
                color: #ccc;
                margin-top: 0;
                margin-bottom: $gutter-small;
            }
        }

        &__embedcode {
            display: flex;
            align-items: center;
            width: 100%;

            > button {
                flex-shrink: 0;
                margin-left: $gutter-small;
            }

            textarea {
                font-size: $font-size-md;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;

            > .recipe__ad {
                position: relative;
                display: flex;
                flex-direction: column;
                margin: $gutter 0;
                width: 100%;

                > .recipe__ad-zone {
                    position: relative;
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    margin-bottom: $gutter;

                    > .ad {
                        position: relative;
                        width: 100%;
                    }
                }
                > .ad-2 {
                    display: none;
                }
            }

            @include breakpoint-min-md {
                flex-direction: row;

                > .recipe__detail {
                    width: 100%;
                    max-width: $breakpoint-sm-min;
                }

                > .recipe__ad {
                    position: relative;
                    order: 2;
                    margin-left: $gutter;
                    width: 50%;

                    > .ad-2 {
                        display: flex;
                    }
                }
            }
        }

        @include breakpoint-min-md {

            &__info {
                margin-bottom: $gutter;
            }

            &__feedback {
                margin-bottom: $gutter-small;
                padding-right: $gutter;
            }

            &__meta {
                max-width: 60%;
                padding-right: $gutter;
    
                > .#{$name}__img {
                    display: none;
                }
            }
    
            &__visual {
                display: block;
            }

            &__userchip {
                margin-bottom: 0;
            }

            &__attrs {
                align-items: center;
                display: flex;
                flex-direction: row;
            }

            &__comments {
                max-width: 400px;
            }

            &--embed {
                .#{$name}__print {
                    margin: 0;
                    top: 12px;
                }
            }
        }

        @media print {
            &__actions {
                display: none;
            }

            .#{$name}__img {
                float: right;
                display: block;
                margin-left: $gutter-xs;
                width: 200px;
            }

            h3 {
                font-size: $font-size-md;
                margin-bottom: 12px;
            }

            p {
                margin: 6px 0;
                font-size: $font-size-sm;
            }

            li {
                font-size: $font-size-sm;
                margin-bottom: 12px;
            }

            li::before {
                font-size: $font-size-sm;
                top: 0;
            }
        }
    }

    .expander__content {
        > .chip {
            margin: 0 5px 5px 0;
        }
    }
}