
@mixin cms(
    $name: 'cms'
) {
    .#{$name} {
        
    }
    
    .modal__wrap .#{$name}__textarea {
        height: 300px;
    }
}