@mixin recipebuilder(
    $name: 'builder'
) {
    .#{$name} {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        max-width: 900px;
        
        &__header {
            display: flex;
            flex-direction: column;
            width: 100%;
    
            @include breakpoint-min-md {
                align-items: center;
                flex-direction: row;
                justify-content: space-between;
            }

            > h1 {
                width: 100%;
            }
        }

        &__help {
            max-width: 400px;
        }

        &__img-btn {
            margin-top: $gutter;
        }
    }

    .info__btns {
        > button {
            margin-right: $gutter-xs;
        }
    }
}