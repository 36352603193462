
@mixin modal(
    $name: 'modal'
) {
    .#{$name} {
        align-items: center;
        display: flex;
        flex-direction: column;
        bottom: 0;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
        
        &__wrap {
            @include boxshadow(3);

            align-items: center;
            background: #fff;
            border-radius: $border-radius-xl;
            margin: 50px 0 0 0;
            max-width: 600px;
            height: auto;
            padding: 20px;
            position: relative;
            width: 90%;
            z-index: 6;
        }

        &__wrap--large {
            max-width: $breakpoint-lg-min;
        }

        &__wrap--xl {
            max-width: $breakpoint-xl-min;
        }
    }
}