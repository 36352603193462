
@mixin footer(
    $name: 'footer'
) {
    .#{$name} {
        border-top: solid 1px $primary-color-4;
        bottom: 0;
        display: flex;
        flex-direction: row;
        padding: $gutter;
        position: relative;

        @media print {
            display: none;
        }

        &__linkgrp {
            display: flex;
            flex-direction: column;
            width: 100%;

            a {
                font-size: $font-size-md;
                margin-top: $gutter-xs;
            }

            > p, a {
                color: $quaternary-color;
            }
            
            > p {
                font-size: $font-size-md;
                margin-top: $gutter-xs;
                margin-bottom: 0;
            }
        }

        &__cp {
            background-color: $quaternary-color;
            bottom: 0;
            display: flex;
            position: fixed;

            > p {
                color: #fff;
                padding: 0 $gutter-medium;

                > a {
                    color: $secondary-color-4;

                    &:hover {
                        color: $secondary-color-2;
                    }
                }
            }
        }

        &__cpbtn {
            margin: $gutter-small;
            width: 150px;
        }

        @include breakpoint-min-sm {
            &__linkgrp {
                width: 50%;
            }
        }

        @include breakpoint-min-md {
            &__linkgrp {
                width: 32%;
            }
        }
    }
}