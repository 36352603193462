
@mixin home(
    $name: 'home'
) {
    .#{$name} {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;

        &__content,
        &__recipes {
            width: 100%;
        }
    }
}