$chip-height: 40px;
$chip-height-large: 75px;

@mixin chip(
    $name: 'chip'
) {
    .#{$name} {
        align-content: center;
        background-color: $tertiary-color;
        border-radius: $border-radius-xl;
        color: #fff;
        display: inline-flex;
        font-size: $font-size-md;
        justify-content: center;
        padding: $button-padding;

        &__text {
            height: $chip-height;
            line-height: $chip-height;
            vertical-align: middle;
            width: inherit;
            max-width: inherit;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .#{$name}--transparent {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        
        &:hover,
        &:focus {
            background-color: transparent;
            box-shadow: none;            
        }
    }

    .#{$name}--highlight {
        font-weight: 700;
    }

    div.#{$name}__img {
        flex-shrink: 0;
        height: $chip-height;
        width: $chip-height;
        margin-right: $gutter-xs;
    }

    .#{$name}--large {
        div.#{$name}__img {
            height: $chip-height-large;
            width: $chip-height-large;
            margin-right: $gutter-small;
        }

        .#{$name}__text {
            height: $chip-height-large;
            line-height: $chip-height-large;
            font-size: $font-size;
        }
    }
}