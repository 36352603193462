

button,
[role="button"] {
    @include boxshadow(1);
    
    border: 0;
    border-radius: $border-radius-sm;
    cursor: pointer;
    color: #fff;
    border-color: $primary-color;
    background-color: $primary-color;
    padding: $button-padding;
    transition: background-color $transition-default;

    &:hover {
        background-color: $primary-color-3;
        box-shadow: none;
    }

    &.cancel {
        background-color: $tertiary-color;
    }
    &.cancel:hover {
        background-color: $quaternary-color;
    }
}

[role="button"]:active,
input[type="button"]:active,
button:active {
    @include boxshadowinset(1);
    background-color: $primary-color-dark-2;
    outline: none;
    border: none;
}

[role="button"]:focus,
input[type="button"]:focus,
button:focus {
    @include boxshadowinset(1);
    background-color: $primary-color-dark-2;
    outline:0;
}

input[type="button"],
button {
    border: 0;
    font: inherit;
    line-height: normal;
    overflow: visible;
    -webkit-appearance: button; /* for input */
    -webkit-user-select: none; /* for button */
       -moz-user-select: none;
        -ms-user-select: none;
}
input::-moz-focus-inner,
button::-moz-focus-inner {
    border: 0;
    padding: $button-padding;
}

/* Make `a` like a button */
[role="button"] {
    cursor: pointer;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    white-space: pre;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
}

.closethis {
    @include boxshadow(2);
    background-color: #fff;
    color: $font-color;
    align-items: center;
    border-radius: 50%;
    display: flex;
    font-size: 36px;
    font-weight: 500;
    height: 40px;
    line-height: 36px;
    justify-content: center;
    padding: 0;
    position: absolute;
    right: -10px;
    top: -10px;
    width: 40px;

    &:hover,
    &:focus {
        background-color: #efefef;
    }
}

@mixin button(
    $name: 'btn'
) {
    .#{$name} {
        &__component {
            align-items: center;
            display: flex;
            position: relative;
        }

        &__sm {
            @include button-sm();
        }

        &__md {
            @include button-md();
        }
        
        &__lg {
            @include button-lg();
        }

        &__secondary {
            background: $secondary-color;

            &:active,
            &:focus,
            &:hover {
                background: $secondary-color-2;
            }
        }

        &__tertiary {
            background: $tertiary-color;

            &:active,
            &:focus,
            &:hover {
                background: $tertiary-color-2;
            }
        }

        &__grey {
            background: $font-color;

            &:active,
            &:focus,
            &:hover {
                background: $font-color;
            }
        }

        &__danger {
            background: $error-color;

            &:active,
            &:focus,
            &:hover {
                background: $error-color-2;
            }
        }
        
        &__transparent {
            background: transparent;
            box-shadow: none;
            color: $font-color;

            &:active,
            &:focus,
            &:hover {
                background: transparent;
                box-shadow: none;
            }
        }
        
        &__icon {
            height: 25px;
            margin-right: $gutter-xxs;
            width: 25px;

            > svg {
                fill: #fff;
                height: inherit;
                position: relative;
                top: 1px;
                width: inherit;
    
                > use {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        &__text {
            color: inherit;
            position: relative;
            top: 1px;
        }

        &__round {
            border-radius: 50%;
            padding: 12px;
        }
    }

    button.icon {
        padding: 0;
        background: transparent;
        box-shadow: none;

        &:active,
        &:focus,
        &:hover {
            background: transparent;
            box-shadow: none;
        }

        &:hover {
            @include boxshadow(1);
        }
    }
}