
@mixin content(
    $name: 'content'
) {
    .#{$name} {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        width: 100%;
        max-width: $breakpoint-md-min;

        @include breakpoint-min-md {
            max-width: $max-width-content;
            padding: 0 $gutter;

            &--body {
                max-width: $max-width-content-body;
            }
        }

        > div {
            max-width: inherit;
        }

        &--center {
            align-items: center;
        }

        &--body {
            img {
                max-width: 100%;
            }
        }
    }
}