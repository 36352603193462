@mixin list(
    $name: 'list'
) {
    .#{$name} {
        align-items: center;
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;

        &__info {
            align-items: flex-start;
            display: flex;
            flex-direction: column;
            margin-bottom: $gutter;
            width: inherit;

            > h4 {
                margin: 0;
            }
        }

        &__name {
            display: flex;
            justify-content: center;
            margin: $gutter-small 0;

            > h1 {
                margin: 0;
            }
        }

        &__icon {
            margin-left: $gutter-xs;
            position: relative;
            top: 3px;
        }

        &__filters {
            align-items: flex-start;
            display: flex;
            margin-bottom: $gutter;
            position: relative;
            width: 100%;

            .form__row {
                width: 200px;
            }
        }

        &__totop {
            padding: 14px;
            position: fixed;
            bottom: $gutter-medium;
            right: $gutter-medium;
            z-index: 1;

            .btn__icon {
                margin: 0;
            }
        }
    }
}