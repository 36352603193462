@mixin adminchart(
    $name: 'adminchart'
) {
    .#{$name} {
        
        &--row {
            display: flex;
            flex-direction: row;
            height: 45px;
            margin-bottom: 6px;

            form {
                display: flex;
            }
        }
    }

    div.#{$name} {
        &--cell {
            flex-grow: 2;
            margin: 0 5px;
            text-align: center;
            width: 150px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            button.tiny {
                height: 100%;
            }
        }

        &--tags {
            width: 100%;
            height: 200px;

            .field {
                height: 100%;
                max-height: none;

                .field__wrap {
                    height: 100%;

                    select {
                        height: 100%;
                    }
                }
            }
        }
    }
    div.#{$name}--cell.#{$name}--cell-sm {
        flex-grow: 1;
        max-width: 75px;
    }

    input.#{$name} {
        
        &--cell {
            flex-grow: 2;
            margin: 0 5px;
            width: 150px;
        }

        &--cell[type="checkbox"] {
            cursor: pointer;
            flex-grow: 1;
            height: 15px;
            margin: 0 0 0 10px;
            position: relative;
            top: 27%;
        }
    }

    input.#{$name}--cell.#{$name}--cell-sm {
        flex-grow: 1;
        max-width: 75px;
    }
}
