
@mixin profile(
    $name: 'profile'
) {
    div.#{$name} {
        align-items: flex-start;
        display: flex;
        justify-content: flex-start;
        padding: $gutter $gutter-small;
        margin-bottom: $gutter-large;
        
        &__user {
            align-items: center;
            display: flex;
            flex-direction: column;

            @include breakpoint-min-md {
                flex-direction: row;
            }
        }

        &__avatar {
            display: flex;
            flex-direction: column;
            margin-bottom: $gutter-xs;

            @include breakpoint-min-md {
                margin-bottom: 0;
            }
        }

        &__meta {
            font-size: $font-size-sm;

            > p {
                margin: $gutter-xs 0;
            }
        }

        &__bio {

            > p {
                margin: 0 $gutter;
            }
        }

        &__delete {
            align-items: center;
            border-top: solid 1px $tertiary-color-4;
            display: flex;
            flex-direction: column;
            margin-top: $gutter-large;
            width: 100%;
        }

    }
    
    h4.#{$name}__h4 {
        width: 100%;
        margin-bottom: $gutter-small;
    }
}