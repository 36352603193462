
@keyframes keyFramesFadeOutOpacity {
    from {
        opacity:1;
    }
    to {
        opacity:0;
    }
}

@keyframes keyFramesFadeInOpacity {
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
}

@mixin blockingAppBackground() {
    background: rgba(0, 0, 0, .3);
}

// https://medium.com/kingrayhan/material-design-box-shadow-147c1d3b6f14
@mixin boxshadow($depth:1) {
    @if $depth==1 {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    @else if $depth==1.5 {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.14), 0 2px 4px rgba(0, 0, 0, 0.24);
    }
    @else if $depth==2 {
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    @else if $depth==3 {
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    }
    @else if $depth==4 {
        box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
    }
    @else if $depth==5 {
        box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
    }
    transition: all $transition-default cubic-bezier(.25, .8, .25, 1);
}

@mixin boxshadowinset($depth:1) {
    @if $depth==1 {
        box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12), inset 0 1px 2px rgba(0, 0, 0, 0.24);
    }
    @else if $depth==2 {
        box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16), inset 0 3px 6px rgba(0, 0, 0, 0.23);
    }
    @else if $depth==3 {
        box-shadow: inset 0 10px 20px rgba(0, 0, 0, 0.19), inset 0 6px 6px rgba(0, 0, 0, 0.23);
    }
    @else if $depth==4 {
        box-shadow: inset 0 14px 28px rgba(0, 0, 0, 0.25), inset 0 10px 10px rgba(0, 0, 0, 0.22);
    }
    @else if $depth==5 {
        box-shadow: inset 0 19px 38px rgba(0, 0, 0, 0.30), inset 0 15px 12px rgba(0, 0, 0, 0.22);
    }
    transition: all $transition-default cubic-bezier(.25, .8, .25, 1);
}

@mixin breakpoint-min-sm {
    @media (min-width: #{$breakpoint-sm-min}) {
        @content;
    }
}

@mixin breakpoint-min-md {
    @media (min-width: #{$breakpoint-md-min}) {
        @content;
    }
}

@mixin breakpoint-min-lg {
    @media (min-width: #{$breakpoint-lg-min}) {
        @content;
    }
}

@mixin breakpoint-min-xl {
    @media (min-width: #{$breakpoint-xl-min}) {
        @content;
    }
}

@mixin breakpoint-sm-to-md {
    @media (min-width: #{$breakpoint-sm-min}) and (max-width: #{$breakpoint-md-min}) {
        @content;
    }
}

@mixin breakpoint-md-to-lg {
    @media (min-width: #{$breakpoint-md-min}) and (max-width: #{$breakpoint-lg-min}) {
        @content;
    }
}

@mixin breakpoint-lg-to-xl {
    @media (min-width: #{$breakpoint-lg-min}) and (max-width: #{$breakpoint-xl-min}) {
        @content;
    }
}

@mixin button-sm {
    font-size: $font-size-sm;
    padding: 7px 12px 10px 12px;
}

@mixin button-md {
    font-size: $font-size-md;
    padding: 10px 18px 12px 18px;
}

@mixin button-lg {
    font-size: $font-size-lg;
    padding: 13px 24px 15px 24px;
}