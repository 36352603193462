@mixin topnav(
    $name: 'topnav'
) {
    .#{$name} {
        @include boxshadow(2);

        align-items: center;
        background-color: $quaternary-color;
        display: flex;
        flex-shrink: 0;
        height: $topnav-height;
        justify-content: space-between;
        padding: 0 $gutter;
        position: relative;
        width: 100%;

        @media print {
            display: none;
        }

        &__group {
            display: flex;
            height: $topnav-height;
            position: relative;
        }

        &__group--start {
        }

        &__group--middle {
            @include boxshadow(2);

            background-color: $secondary-color;
            display: none;
            flex-direction: column;
            height: auto;
            position: absolute;
            top: $topnav-height;
            width: 100%;
            left: 0;
            z-index: 3;

            @include breakpoint-min-md {
                background-color: transparent;
                box-shadow: none;
                display: flex;
                flex-direction: row;
                flex-grow: 3;
                justify-content: flex-end;
                position: relative;
                top: 0;
                width: auto;
            }

            &open {
                display: flex;
            }

            > .#{$name}__user {
                @include breakpoint-min-md {
                    display: none;
                }
            }
        }

        &__search {
            @include breakpoint-min-md {
                margin-right: $gutter-xs;
                margin-top: 15px;
            }

            @include breakpoint-min-lg {
                margin-right: $gutter-small;
                margin-top: 15px;
            }
        }

        &__group--end {
            flex-direction: row-reverse;

            @include breakpoint-min-md {
                min-width: 90px;
            }

            > .#{$name}__user {
                display: none;

                @include breakpoint-min-md {
                    align-items: center;
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    right: -1 * $gutter;
                    height: $topnav-height;
                    top: 0;
                }
            }
        }

        &__hamburger {
            width: 50px;
            height: $topnav-height;
            padding: 0;
            
            @include breakpoint-min-md {
                display: none;
            }

            > svg {
                fill: #fff;
                height: inherit;
                width: inherit;
                position: relative;
            }
            
        }


        &__link {
            border-top: solid 1px $secondary-color-2;
            align-items: center;
            display: flex;
            flex-shrink: 0;
            height: $topnav-link-height-sm;
            position: relative;
            width: 100%;

            @include breakpoint-min-md {
                border-top: none;
                height: $topnav-height;
                width: auto;
            }

            &:hover {
                background-color: $quaternary-dark-1;
                
                > .#{$name}__tab {
                    color: $primary-color;
                }
            }

            &:focus > .#{$name}__tab,
            &.active > .#{$name}__tab {
                background-color: $quaternary-dark-1;
                color: $primary-color;

                @include breakpoint-min-md {
                    background-color: transparent;
                }
            }
        }

        &__link > .#{$name}__tab {
            color: #fff;
            display: flex;
            flex-direction: column;
            font-size: 16px;
            height: inherit;
            justify-content: center;
            margin: 0;
            padding: 0 $gutter-small;
            position: relative;
            width: inherit;

            @include breakpoint-min-md {
                padding: 0 $gutter-xs;
            }

            @include breakpoint-min-lg {
                padding: 0 $gutter-small;
            }
        }

        &__group--start {
            .#{$name}__link {
                border: none;
            }

            @include breakpoint-min-md {
                width: 150px;
            }

            @include breakpoint-min-lg {
                width: 200px;
            }
        }

        &__logo {
            display: flex;
            height: $topnav-height;
            left: -1 * $gutter;
            padding: 0 $gutter-small;
            position: relative;
            line-height: $topnav-height;
            width: 180px;

            .logo {
                color: #fff;
                position: absolute;
                left: 75px;
                font-size: 20px;
                margin: 0;
            }

            > svg {
                flex-shrink: 0;
                fill: #fff;
                width: 55px;
                height: 55px;
                position: relative;
                left: -5px;
            }
        }

        &__active {
            border-bottom: solid 5px $primary-color;
            bottom: 0;
            display: none;
            height: 5px;
            position: absolute;
            left: 0;
            width: 50px;
            z-index: 4;
        }

        &__signin {
            background: transparent;
            box-shadow: none;
            color: #fff;
            height: $topnav-link-height-sm;
            padding-left: $gutter-small;
            text-align: left;
            width: 100%;
            
            &:active,
            &:focus,
            &:hover {
                background: transparent;
            }

            @include breakpoint-min-md {
                @include boxshadow(1);
                background-color: $primary-color;
                position: relative;
                right: $gutter;
                top: 12px;

                &:active,
                &:focus,
                &:hover {
                    background: $primary-color-3;
                }
            }
        }
    }
}