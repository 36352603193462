
@mixin icon(
    $name: 'icon'
) {
    .#{$name} {
        fill: $tertiary-color;
        
        &--sm {
            width: 24px;
            height: 24px;
        }
        
        &--md {
            width: 36px;
            height: 36px;
        }

        &--primary {
            fill: $primary-color;
        }

        &--secondary {
            fill: $secondary-color;
        }
    }
}