
$short-field-delta: 8px;
$field-border: solid 2px $secondary-color-2;

@mixin field(
    $name: 'field'
) {
    .#{$name} {
        align-items: center;
        display: flex;
        background-color: #fff;
        border: $field-border;
        border-radius: $border-radius-sm;
        height: $form-height-inline-element;
        min-height: $form-height-inline-element;
        max-height: $form-height-inline-element;
        padding: 0 1px;
        position: relative;
        width: 100%;
        
        &--focus {
            border: solid 2px $primary-color-3;
        }
        
        &--noborder {
            border: none;
        }

        &--textarea {
            height: $form-height-textarea;
            min-height: $form-height-textarea;
            max-height: $form-height-textarea;

            .#{$name}__label {
                top: -60px;
            }

            .#{$name}__label--move {
                top: -88px;
            }

            .#{$name}__error {
                top: $form-height-textarea/2 + 20;
                z-index: 1;
            }
        }
        
        &--textareaSm {
            height: $form-height-textarea-small;
            min-height: $form-height-textarea-small;
            max-height: $form-height-textarea-small;
            
            textarea {
                font-size: 16px; // needs to be 16 on mobile to prevent zooming
            }

            .#{$name}__label {
                top: -5px;
            }

            .#{$name}__label--move {
                top: -25px;
            }

            .#{$name}__error {
                top: $form-height-textarea-small/2 + 20;
                z-index: 1;
            }
        }

        &__label {
            border-radius: $border-radius-sm;
            background-color: #fff;
            color: $quaternary-color;
            cursor: pointer;
            display: block;
            font-size: $font-size-md;
            left: 10px;
            padding: 0 5px;
            margin-left: -5px;
            position: absolute;
            transition-property: top;
            transition-duration: $transition-default;
            top: 14px;
            z-index: 2;
        }

        &__label--move {
            top: -12px;
            padding: 0 8px;
        }

        &__label--hide {
            display: none;
        }

        &__input {
            border: none;
            border-radius: $border-radius-sm;
            font-size: 16px; // needs to be 16 on mobile to prevent zooming
            padding: 0 10px;
            vertical-align: middle;
            height: $form-height-inline-element - 6px;
            width: 99%;
        }

        &__input:focus {
            outline: none;
        }

        &__input--textarea {
            height: $form-height-textarea - 6px;
            padding-top: 10px;
            padding-bottom: 10px;
            resize: none;
        }

        &__input--textareaSm {
            height: $form-height-textarea-small - 6px;
        }
        
        &__wrap {
            align-items: center;
            display: flex;
            flex: 3 0 auto;
            height: $form-height-inline-element - 4px;
            position: relative;
            width: 75%;
        }

        &__error {
            background-color: #fff;
            color: $error-color;
            font-size: $font-size-sm;
            margin-top: -8px;
            max-width: inherit;
            margin-left: 5px;
            padding: 0 5px;
            position: absolute;
            top: $form-height-inline-element;
        }

        &__save {
            margin-left: 20px;
        }

        > svg {
            fill: $secondary-color-2;
            height: 24px;
            position: relative;
            width: 24px;
            margin-left: 5px;

            > use {
                height: 100%;
                width: 100%;
            }
        }

        &__selecttime {
            align-items: center;
            border: $field-border;
            border-radius: $border-radius-sm;
            display: flex;
            flex-direction: row;
            justify-content: center;
            width: 120px;
        }

        &--focus {
            > svg {
                fill: $primary-color-3;
            }
        }

        // this error > svg must be last to
        // override focus and other fill colors
        &--error {
            border-color: $error-color;

            > svg {
                fill: $error-color;
            }
        }

        &__colon {
            font-size: 24px;
            height: 30px;
            line-height: 30px;
            position: relative;
            top: -1px;
        }

        &__toggles {
            display: flex;
            width: 100%;
        }

        &__toggle {
            border: none;
            margin-top: 20px;
            height: auto;
            max-height: none;
            min-height: none;

            .#{$name}__label {
                left: 5px;
                top: -22px;
            }

            .#{$name}__wrap {
                flex: none;
                flex-direction: column;
                width: auto;
                height: auto;
            }
        }

        &__toggle-btn {
            align-items: center;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-right: $gutter-small;
            width: 36px;
            height: 36px;
            padding: 0;

            > svg {
                display: none;
                fill: #fff;
                width: 28px;
                height: 28px;
            }

            &--active {
                box-shadow: none;

                > svg {
                    display: block;
                }
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
    
    .field__style--filter {
        .#{$name} {
            border: solid 1px $tertiary-color-2;
        }
    }
    
    .field__style--short {
        .#{$name} {
            height: $form-height-inline-element - $short-field-delta;
            min-height: $form-height-inline-element - $short-field-delta;
            max-height: $form-height-inline-element - $short-field-delta;

            &__wrap {
                height: $form-height-inline-element - $short-field-delta - 4px;
            }

            &__input {
                height: $form-height-inline-element - $short-field-delta - 6px;
                font-size: 16px; // needs to be 16 on mobile to prevent zooming
            }

            &__label {
                font-size: 13px;
                top: 11px;
            }
        }

    }

    .select__wrap.field__style--filter::after {
        color: $tertiary-color-2;
    }

    .field__selecttime {
        .#{$name} {
            text-align-last:center;
            padding-left: 2px;
            padding-right: 2px;
        }
    }
}