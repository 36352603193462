@mixin feed(
    $name: 'feed'
) {
    .#{$name} {
        display: flex;
        flex-wrap: wrap;
        width: inherit;

        &__footer {
            align-items: center;
            display: flex;
            flex-direction: column;
            padding: $gutter 0;
            width: 100%;
        }
    }
}