
@mixin alertbar(
    $name: 'alertbar'
) {
    .#{$name} {
        @include boxshadow(1);

        align-items: center;
        animation: keyFramesFadeInOpacity .5s;
        background-color: $secondary-color;
        color: white;
        display: flex;
        flex-direction: row;
        justify-content: center;
        opacity: 1;
        padding: 10px;
        position: fixed;
        transition-duration: $transition-default;
        transition-property: opacity;
        width: 100%;
        z-index: 5;

        &__content {
            color: inherit;
        }

        &__btnwrap {
            color: inherit;
            background-color: inherit;
            position: relative;
            flex-basis: 75px;
        }

        &__close {
            background-color: inherit;
            color: inherit;
            position: absolute;
            right: auto;
            left: $gutter-p;

            &:hover,
            &:focus {
                background-color: inherit;
            }
        }
    }

    .#{$name}--error {
        background-color: $error-color;
    }
}