$username-wrapper-max-width: 150px;
$avatar-margin-right: 10px;

@mixin usermenu(
    $name: 'usermenu'
) {
    .#{$name} {
        border-bottom-left-radius: $border-radius-md;
        border-bottom-right-radius: $border-radius-md;
        display: flex;
        flex-direction: column;
        flex-shrink: 0;
        position: relative;
        width: inherit;

        @include breakpoint-min-md {
            max-width: $username-wrapper-max-width + $gutter + $gutter-medium;
            min-width: 175px;
            right: 0;
            top: 0;
            z-index: 1;
        }

        &__wrap {
            position: relative;
            margin: 0;
            padding: 0;
            width: 100%;
        }

        &--options {
            background-color: $secondary-color;
            color: #fff;
            overflow: hidden;
        }

        div.img--round.#{$name}__avatar {
            flex-shrink: 0;
            margin-right: $avatar-margin-right;
            height: $avatar-width-sm;
            width: $avatar-width-sm;

            @include breakpoint-min-md {
                height: $avatar-width;
                width: $avatar-width;
            }
        }

        &__name {
            color: #fff;
            max-width: $username-wrapper-max-width - $avatar-width-sm - $avatar-margin-right;
            padding-right: $gutter-xs;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        &--options {
            .usermenu__name {
                color: #fff;
            }
            .usermenu__item {
                color: #fff;
            }
        }

        &__svgwrap {
            border: solid 1.5px $secondary-color;
            border-radius: 50%;
            overflow: hidden;
            position: relative;
        }

        &__svg {
            bottom: -100px;
            fill: $secondary-color;
            height: 120%;
            left: -100px;
            margin: auto;
            position: absolute;
            right: -100px;
            top: -90px;
            width: 120%;
        }

        &__top {
            align-items: center;
            display: flex;
            flex-shrink: 0;
            height: $topnav-link-height-sm;
            padding-left: $gutter-small;
            position: relative;

            @include breakpoint-min-md {
                height: $topnav-height;
            }
        }

        &__options {
            display: none;
        }

        &__options--show {
            display: flex;
            flex-direction: column;
        }

        &__item {
            border-top: solid 1px $secondary-color-2;
            height: $topnav-link-height-sm;
            line-height: $topnav-link-height-sm;
            padding: 0 0 0 $gutter-large;
            width: 100%;
            text-align: left;
        }

        button.#{$name}__item,
        a.#{$name}__item {
            border-radius: 0;
            background-color: $secondary-color;

            &:hover {
                background-color: $secondary-color-2;
            }
                        
            &.active {
                background-color: $quaternary-dark-1;
                color: $primary-color;
            }
        }
    }
}