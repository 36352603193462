
@mixin comments(
    $name: 'comments'
) {
    .#{$name} {
        position: relative;
        width: 100%;

        &__feed {
            > .feed {
                display: flex;
                flex-direction: column;
                position: relative;
                width: 100%;

                > h4 {
                    font-weight: normal;
                }
            }
        }

        &__comment {
            display: flex;
            flex-direction: row;
            margin: $gutter-xs 0;
            position: relative;
            width: 100%;
        }

        &__editor {
            margin-bottom: $gutter-small;

            > h4 {
                margin: 0;
            }

            .form.gutters-large {
                margin-top: $gutter-small;
                
                > .form__row {
                    margin-top: 0;
                }
            }

            .form__btns {
                margin-top: $gutter-small;
                justify-content: flex-end;
            }

            .form__btngroup {
                margin-left: $gutter-small;
                width: auto;

                > button {
                    @include button-md();
                }
            }
        }

        &__signin {
            margin: $gutter-medium 0 0 0;
        }

        &__sidebar {
            align-content: center;
            display: flex;
            flex-direction: column;
            margin-right: $gutter-xs;
            position: relative;
            width: 45px;

            .chip__img {
                margin-right: 0;
            }
        }

        &__meta {
            align-items: center;
            display: flex;
            flex-direction: row;
            position: relative;
            margin-bottom: $gutter-xxs;

            > button {
                margin-left: $gutter-xs;
                position: relative;
                top: 3px;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;

            > p {
                margin: 0;
            }
        }

        &__username {
            font-weight: 700;
            margin-right: $gutter-xs;
        }

        &__timeago {
            font-size: $font-size-sm;
            margin-right: $gutter-xs;
            position: relative;
            top: 1px;
        }
    }
}