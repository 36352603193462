@keyframes keyFramesFadeOutOpacity {
  from {
    opacity: 1; }
  to {
    opacity: 0; } }

@keyframes keyFramesFadeInOpacity {
  from {
    opacity: 0; }
  to {
    opacity: 1; } }

html, body {
  height: 100%; }

html {
  box-sizing: border-box; }

*,
*:before,
*:after {
  box-sizing: inherit;
  color: #444;
  margin-block-end: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: "open sans", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace; }

a {
  color: #d67808;
  cursor: pointer;
  text-decoration: none; }

p,
li {
  line-height: 24px; }

p {
  margin-bottom: 16px;
  margin-top: 16px; }

#root {
  position: relative;
  width: 100%;
  height: 100%; }

h1, h2, h3, h4, h5, h6 {
  line-height: normal;
  font-weight: 500; }

h1 {
  font-size: 24px; }

h2, h3 {
  font-size: 18px; }

h4, h5, h6 {
  font-size: 16px; }

.color__secondary {
  color: #00AAA6; }

.bold {
  font-weight: 700; }

textarea {
  line-height: 26px;
  padding: 10px;
  width: 100%;
  font-size: 16px; }

div.app-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  height: 100%;
  position: relative;
  width: 100%; }

div.app-view {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  align-items: center;
  overflow: hidden;
  padding: 48px 24px;
  width: 100%;
  min-height: 800px; }
  @media print {
    div.app-view {
      padding-top: 0;
      min-height: 0; } }

div.view-content {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding-bottom: 24px;
  padding-left: 24px;
  padding-right: 24px;
  width: 100%; }
  div.view-content > h4 {
    width: 100%; }

div.app-loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  width: 100%;
  height: 100%; }

div.img--round {
  background-position: center top;
  background-size: cover;
  border-radius: 50%;
  height: 50px;
  width: 50px; }

.error-color {
  color: #E82711; }

.logo {
  color: #F7941E;
  font-family: Lobster;
  font-size: 30px;
  font-weight: normal; }

#app-preload {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%; }
  #app-preload > h1 {
    font-size: 18px;
    font-weight: normal; }
  #app-preload > h1,
  #app-preload > .preloader-wrapper {
    margin-top: 32px; }
  #app-preload .footer {
    position: absolute; }

button,
[role="button"] {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  border: 0;
  border-radius: 5px;
  cursor: pointer;
  color: #fff;
  border-color: #F7941E;
  background-color: #F7941E;
  padding: 7px 20px 9px 20px;
  transition: background-color 0.1s; }
  button:hover,
  [role="button"]:hover {
    background-color: #f9a94b;
    box-shadow: none; }
  button.cancel,
  [role="button"].cancel {
    background-color: #9F8B8A; }
  button.cancel:hover,
  [role="button"].cancel:hover {
    background-color: #565353; }

[role="button"]:active,
input[type="button"]:active,
button:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12), inset 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #d67808;
  outline: none;
  border: none; }

[role="button"]:focus,
input[type="button"]:focus,
button:focus {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.12), inset 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #d67808;
  outline: 0; }

input[type="button"],
button {
  border: 0;
  font: inherit;
  line-height: normal;
  overflow: visible;
  -webkit-appearance: button;
  /* for input */
  -webkit-user-select: none;
  /* for button */
  -moz-user-select: none;
  -ms-user-select: none; }

input::-moz-focus-inner,
button::-moz-focus-inner {
  border: 0;
  padding: 7px 20px 9px 20px; }

/* Make `a` like a button */
[role="button"] {
  cursor: pointer;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  white-space: pre;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; }

.closethis {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #fff;
  color: #444;
  align-items: center;
  border-radius: 50%;
  display: flex;
  font-size: 36px;
  font-weight: 500;
  height: 40px;
  line-height: 36px;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: -10px;
  top: -10px;
  width: 40px; }
  .closethis:hover, .closethis:focus {
    background-color: #efefef; }

.input-required {
  color: #E82711;
  position: relative;
  left: 2px; }

.app-loading {
  position: relative; }
  .app-loading .app-loading__reveal {
    animation: keyFramesFadeOutOpacity 3s;
    opacity: 0;
    background-color: #fff;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1; }

.circle {
  border-radius: 50%; }

.preloader-wrapper {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px; }

.preloader-wrapper.small {
  width: 36px;
  height: 36px; }

.preloader-wrapper.big {
  width: 64px;
  height: 64px; }

.preloader-wrapper.active {
  -webkit-animation: container-rotate 1568ms linear infinite;
  animation: container-rotate 1568ms linear infinite; }

@-webkit-keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg); } }

@keyframes container-rotate {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg); } }

.spinner-layer {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-color: #F7941E; }

.spinner-color,
.spinner-color-only {
  border-color: #F7941E; }

.white > .spinner-layer {
  border-color: #fff; }

.white > .spinner-color,
.white > .spinner-color-only {
  border-color: #fff; }

.active .spinner-layer.spinner-color {
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, color-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both, color-fade-in-out 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.active .spinner-layer,
.active .spinner-layer.spinner-color-only {
  opacity: 1;
  -webkit-animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

@-webkit-keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg); } }

@keyframes fill-unfill-rotate {
  12.5% {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg); }
  25% {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg); }
  37.5% {
    -webkit-transform: rotate(405deg);
    transform: rotate(405deg); }
  50% {
    -webkit-transform: rotate(540deg);
    transform: rotate(540deg); }
  62.5% {
    -webkit-transform: rotate(675deg);
    transform: rotate(675deg); }
  75% {
    -webkit-transform: rotate(810deg);
    transform: rotate(810deg); }
  87.5% {
    -webkit-transform: rotate(945deg);
    transform: rotate(945deg); }
  to {
    -webkit-transform: rotate(1080deg);
    transform: rotate(1080deg); } }

@-webkit-keyframes color-fade-in-out {
  from {
    opacity: 1; }
  25% {
    opacity: 1; }
  26% {
    opacity: 0; }
  89% {
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    opacity: 1; } }

@keyframes color-fade-in-out {
  from {
    opacity: 1; }
  25% {
    opacity: 1; }
  26% {
    opacity: 0; }
  89% {
    opacity: 0; }
  90% {
    opacity: 1; }
  100% {
    opacity: 1; } }

.gap-patch {
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }

.gap-patch .circle {
  width: 1000%;
  left: -450%; }

.circle-clipper {
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit; }

.circle-clipper .circle {
  width: 200%;
  height: 100%;
  border-width: 3px;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent !important;
  border-radius: 50%;
  -webkit-animation: none;
  animation: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0; }

.circle-clipper.left .circle {
  left: 0;
  border-right-color: transparent !important;
  -webkit-transform: rotate(129deg);
  transform: rotate(129deg); }

.circle-clipper.right .circle {
  left: -100%;
  border-left-color: transparent !important;
  -webkit-transform: rotate(-129deg);
  transform: rotate(-129deg); }

.active .circle-clipper.left .circle {
  -webkit-animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

.active .circle-clipper.right .circle {
  -webkit-animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
  animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both; }

@-webkit-keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg); } }

@keyframes left-spin {
  from {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg); }
  50% {
    -webkit-transform: rotate(-5deg);
    transform: rotate(-5deg); }
  to {
    -webkit-transform: rotate(130deg);
    transform: rotate(130deg); } }

@-webkit-keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg); } }

@keyframes right-spin {
  from {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg); }
  50% {
    -webkit-transform: rotate(5deg);
    transform: rotate(5deg); }
  to {
    -webkit-transform: rotate(-130deg);
    transform: rotate(-130deg); } }

.admin {
  display: flex;
  width: 100%; }
  .admin__nav {
    display: flex;
    flex-direction: column;
    margin-right: 48px; }
    .admin__nav a[role='button'] {
      margin-bottom: 8px; }
  .admin__view {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .admin__view > h1 {
      margin-top: 0; }

.itemedit {
  display: flex;
  width: 100%;
  margin: 24px 0 32px 0; }
  .itemedit > .search__field {
    max-width: 300px; }

.adminchart--row {
  display: flex;
  flex-direction: row;
  height: 45px;
  margin-bottom: 6px; }
  .adminchart--row form {
    display: flex; }

div.adminchart--cell {
  flex-grow: 2;
  margin: 0 5px;
  text-align: center;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  div.adminchart--cell button.tiny {
    height: 100%; }

div.adminchart--tags {
  width: 100%;
  height: 200px; }
  div.adminchart--tags .field {
    height: 100%;
    max-height: none; }
    div.adminchart--tags .field .field__wrap {
      height: 100%; }
      div.adminchart--tags .field .field__wrap select {
        height: 100%; }

div.adminchart--cell.adminchart--cell-sm {
  flex-grow: 1;
  max-width: 75px; }

input.adminchart--cell {
  flex-grow: 2;
  margin: 0 5px;
  width: 150px; }

input.adminchart--cell[type="checkbox"] {
  cursor: pointer;
  flex-grow: 1;
  height: 15px;
  margin: 0 0 0 10px;
  position: relative;
  top: 27%; }

input.adminchart--cell.adminchart--cell-sm {
  flex-grow: 1;
  max-width: 75px; }

.alertbar {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  align-items: center;
  animation: keyFramesFadeInOpacity .5s;
  background-color: #00AAA6;
  color: white;
  display: flex;
  flex-direction: row;
  justify-content: center;
  opacity: 1;
  padding: 10px;
  position: fixed;
  transition-duration: 0.1s;
  transition-property: opacity;
  width: 100%;
  z-index: 5; }
  .alertbar__content {
    color: inherit; }
  .alertbar__btnwrap {
    color: inherit;
    background-color: inherit;
    position: relative;
    flex-basis: 75px; }
  .alertbar__close {
    background-color: inherit;
    color: inherit;
    position: absolute;
    right: auto;
    left: 16px; }
    .alertbar__close:hover, .alertbar__close:focus {
      background-color: inherit; }

.alertbar--error {
  background-color: #E82711; }

.auth-si {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%; }

.auth-si--inline h2 {
  font-size: 18px;
  margin-bottom: 0; }
  .auth-si--inline h2 span.logo {
    font-size: 20px; }

.auth-si__forgotpass {
  margin: 24px 0; }

.auth-si__title {
  margin-bottom: 0; }

.auth-si__sucta {
  margin-top: 16px; }

.blog {
  display: flex;
  flex-direction: column; }
  .blog > h1 {
    font-weight: 600; }
  .blog__body > h2,
  .blog__body > h3,
  .blog__body > h4 {
    font-weight: 600; }
  .blog__summary {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    max-width: 300px;
    margin-bottom: 24px; }
    @media (min-width: 576px) {
      .blog__summary {
        flex-direction: row;
        max-width: none; }
        .blog__summary > img.blog__s-img {
          max-width: 200px; } }
  .blog__s-detail {
    padding: 16px;
    width: 100%; }
  .blog__s-date {
    color: #9F8B8A;
    font-size: 14px; }
    @media (min-width: 576px) {
      .blog__s-date {
        margin-left: 24px; } }
  .blog__s-meta {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    @media (min-width: 576px) {
      .blog__s-meta {
        justify-content: start; } }
  .blog__s-title {
    margin-bottom: 24px; }
  .blog__s-desc {
    max-height: 40px; }
  .blog__s-img {
    align-self: center;
    max-width: 400px;
    min-height: 200px; }
  .blog__p-img-wrap {
    display: flex;
    width: 100%;
    justify-content: center; }
    .blog__p-img-wrap > .blog__p-img {
      max-width: 800px;
      margin: 24px 0; }
  .blog__summary-hp-detail {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    background-position: 50% 50%;
    background-size: cover;
    padding-bottom: 100%;
    width: 100%;
    position: relative; }
  .blog__summary-hp-title {
    background: rgba(86, 83, 83, 0.8);
    bottom: 0;
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    min-height: 60px;
    justify-content: center;
    padding: 16px;
    position: absolute;
    width: 100%; }

.blog__summary-hp,
.home__flavors {
  width: 100%;
  height: 100%;
  margin: 8px 0; }
  @media (min-width: 576px) {
    .blog__summary-hp,
    .home__flavors {
      min-width: 50%;
      width: 50%;
      margin: 16px 0;
      padding: 0 16px; } }
  @media (min-width: 992px) {
    .blog__summary-hp,
    .home__flavors {
      min-width: 33%;
      width: 33%; } }

.home__flavors {
  height: 430px;
  overflow: hidden; }
  .home__flavors > h3 {
    margin-top: 0; }
  .home__flavors > .flavor__sample {
    justify-content: flex-start;
    padding-top: 8px; }
  @media (min-width: 576px) {
    .home__flavors {
      height: 375px; } }
  @media (min-width: 768px) {
    .home__flavors {
      height: 380px; } }
  @media (min-width: 1200px) {
    .home__flavors {
      height: 430px; } }

.btn__component {
  align-items: center;
  display: flex;
  position: relative; }

.btn__sm {
  font-size: 12px;
  padding: 7px 12px 10px 12px; }

.btn__md {
  font-size: 14px;
  padding: 10px 18px 12px 18px; }

.btn__lg {
  font-size: 18px;
  padding: 13px 24px 15px 24px; }

.btn__secondary {
  background: #00AAA6; }
  .btn__secondary:active, .btn__secondary:focus, .btn__secondary:hover {
    background: #68bab8; }

.btn__tertiary {
  background: #9F8B8A; }
  .btn__tertiary:active, .btn__tertiary:focus, .btn__tertiary:hover {
    background: #b3a2a1; }

.btn__grey {
  background: #444; }
  .btn__grey:active, .btn__grey:focus, .btn__grey:hover {
    background: #444; }

.btn__danger {
  background: #E82711; }
  .btn__danger:active, .btn__danger:focus, .btn__danger:hover {
    background: #ef3924; }

.btn__transparent {
  background: transparent;
  box-shadow: none;
  color: #444; }
  .btn__transparent:active, .btn__transparent:focus, .btn__transparent:hover {
    background: transparent;
    box-shadow: none; }

.btn__icon {
  height: 25px;
  margin-right: 4px;
  width: 25px; }
  .btn__icon > svg {
    fill: #fff;
    height: inherit;
    position: relative;
    top: 1px;
    width: inherit; }
    .btn__icon > svg > use {
      height: 100%;
      width: 100%; }

.btn__text {
  color: inherit;
  position: relative;
  top: 1px; }

.btn__round {
  border-radius: 50%;
  padding: 12px; }

button.icon {
  padding: 0;
  background: transparent;
  box-shadow: none; }
  button.icon:active, button.icon:focus, button.icon:hover {
    background: transparent;
    box-shadow: none; }
  button.icon:hover {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1); }

.card {
  margin: 8px 0;
  padding: 0 8px;
  min-width: 100%;
  width: 100%; }
  @media (min-width: 576px) {
    .card {
      min-width: 50%;
      width: 50%; } }
  @media (min-width: 768px) {
    .card {
      min-width: 33%;
      width: 33%; } }
  @media (min-width: 992px) {
    .card {
      min-width: 25%;
      width: 25%; } }
  @media (min-width: 1200px) {
    .card {
      min-width: 20%;
      width: 20%; } }
  .card__view {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    width: 100%; }
  .card__img {
    background-color: #fff;
    background-position: 50% 50%;
    background-size: cover;
    padding-bottom: 100%; }
  .card__info {
    padding: 16px 8px;
    width: 100%; }
    .card__info > h3 {
      margin-top: 0;
      margin-bottom: 16px; }
  .card__title {
    font-size: 14px;
    font-weight: 600;
    margin: 8px 0 24px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%; }
    .card__title:hover {
      overflow: visible;
      white-space: normal; }
  .card__user {
    align-items: center;
    display: flex;
    width: 100%; }
  .card__avatar {
    height: 30px;
    width: 30px; }
  .card__username {
    font-size: 12px;
    line-height: 16px;
    margin: 0;
    overflow: hidden;
    padding: 0 10px;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .card__top {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1; }
  .card__top--flexend {
    justify-content: flex-end; }

.chip {
  align-content: center;
  background-color: #9F8B8A;
  border-radius: 16px;
  color: #fff;
  display: inline-flex;
  font-size: 14px;
  justify-content: center;
  padding: 7px 20px 9px 20px; }
  .chip__text {
    height: 40px;
    line-height: 40px;
    vertical-align: middle;
    width: inherit;
    max-width: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }

.chip--transparent {
  background-color: transparent;
  box-shadow: none;
  padding: 0; }
  .chip--transparent:hover, .chip--transparent:focus {
    background-color: transparent;
    box-shadow: none; }

.chip--highlight {
  font-weight: 700; }

div.chip__img {
  flex-shrink: 0;
  height: 40px;
  width: 40px;
  margin-right: 8px; }

.chip--large div.chip__img {
  height: 75px;
  width: 75px;
  margin-right: 16px; }

.chip--large .chip__text {
  height: 75px;
  line-height: 75px;
  font-size: 16px; }

.modal__wrap .cms__textarea {
  height: 300px; }

.comments {
  position: relative;
  width: 100%; }
  .comments__feed > .feed {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%; }
    .comments__feed > .feed > h4 {
      font-weight: normal; }
  .comments__comment {
    display: flex;
    flex-direction: row;
    margin: 8px 0;
    position: relative;
    width: 100%; }
  .comments__editor {
    margin-bottom: 16px; }
    .comments__editor > h4 {
      margin: 0; }
    .comments__editor .form.gutters-large {
      margin-top: 16px; }
      .comments__editor .form.gutters-large > .form__row {
        margin-top: 0; }
    .comments__editor .form__btns {
      margin-top: 16px;
      justify-content: flex-end; }
    .comments__editor .form__btngroup {
      margin-left: 16px;
      width: auto; }
      .comments__editor .form__btngroup > button {
        font-size: 14px;
        padding: 10px 18px 12px 18px; }
  .comments__signin {
    margin: 24px 0 0 0; }
  .comments__sidebar {
    align-content: center;
    display: flex;
    flex-direction: column;
    margin-right: 8px;
    position: relative;
    width: 45px; }
    .comments__sidebar .chip__img {
      margin-right: 0; }
  .comments__meta {
    align-items: center;
    display: flex;
    flex-direction: row;
    position: relative;
    margin-bottom: 4px; }
    .comments__meta > button {
      margin-left: 8px;
      position: relative;
      top: 3px; }
  .comments__content {
    display: flex;
    flex-direction: column; }
    .comments__content > p {
      margin: 0; }
  .comments__username {
    font-weight: 700;
    margin-right: 8px; }
  .comments__timeago {
    font-size: 12px;
    margin-right: 8px;
    position: relative;
    top: 1px; }

.confirm {
  display: flex;
  flex-direction: column; }
  .confirm__action {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 16px 0; }
    .confirm__action > button {
      margin: 0 16px;
      min-width: 75px; }

.content {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  width: 100%;
  max-width: 768px; }
  @media (min-width: 768px) {
    .content {
      max-width: 1024px;
      padding: 0 24px; }
      .content--body {
        max-width: 768px; } }
  .content > div {
    max-width: inherit; }
  .content--center {
    align-items: center; }
  .content--body img {
    max-width: 100%; }

.expander {
  position: relative;
  margin-top: 32px; }
  .expander__wrap {
    max-height: 36px;
    overflow: hidden;
    position: relative;
    transition-property: max-height; }
  .expander__more {
    background-color: transparent;
    box-shadow: none;
    color: #00AAA6;
    position: absolute;
    right: 25px;
    top: -30px;
    z-index: 1; }
    .expander__more:hover, .expander__more:focus {
      background-color: transparent;
      box-shadow: none;
      color: #9acdcc; }
      .expander__more:hover:before, .expander__more:focus:before {
        color: #9acdcc; }
    .expander__more:before {
      color: #00AAA6;
      content: '\203A';
      font-size: 24px;
      font-weight: 500;
      left: 0px;
      position: absolute;
      top: .5px;
      transform: rotate(90deg); }
  .expander__more.rotate-caret:before {
    left: -6px;
    transform: rotate(-90deg); }

.feed {
  display: flex;
  flex-wrap: wrap;
  width: inherit; }
  .feed__footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 24px 0;
    width: 100%; }

.field {
  align-items: center;
  display: flex;
  background-color: #fff;
  border: solid 2px #68bab8;
  border-radius: 5px;
  height: 48px;
  min-height: 48px;
  max-height: 48px;
  padding: 0 1px;
  position: relative;
  width: 100%; }
  .field--focus {
    border: solid 2px #f9a94b; }
  .field--noborder {
    border: none; }
  .field--textarea {
    height: 200px;
    min-height: 200px;
    max-height: 200px; }
    .field--textarea .field__label {
      top: -60px; }
    .field--textarea .field__label--move {
      top: -88px; }
    .field--textarea .field__error {
      top: 120px;
      z-index: 1; }
  .field--textareaSm {
    height: 75px;
    min-height: 75px;
    max-height: 75px; }
    .field--textareaSm textarea {
      font-size: 16px; }
    .field--textareaSm .field__label {
      top: -5px; }
    .field--textareaSm .field__label--move {
      top: -25px; }
    .field--textareaSm .field__error {
      top: 57.5px;
      z-index: 1; }
  .field__label {
    border-radius: 5px;
    background-color: #fff;
    color: #565353;
    cursor: pointer;
    display: block;
    font-size: 14px;
    left: 10px;
    padding: 0 5px;
    margin-left: -5px;
    position: absolute;
    transition-property: top;
    transition-duration: 0.1s;
    top: 14px;
    z-index: 2; }
  .field__label--move {
    top: -12px;
    padding: 0 8px; }
  .field__label--hide {
    display: none; }
  .field__input {
    border: none;
    border-radius: 5px;
    font-size: 16px;
    padding: 0 10px;
    vertical-align: middle;
    height: 42px;
    width: 99%; }
  .field__input:focus {
    outline: none; }
  .field__input--textarea {
    height: 194px;
    padding-top: 10px;
    padding-bottom: 10px;
    resize: none; }
  .field__input--textareaSm {
    height: 69px; }
  .field__wrap {
    align-items: center;
    display: flex;
    flex: 3 0 auto;
    height: 44px;
    position: relative;
    width: 75%; }
  .field__error {
    background-color: #fff;
    color: #E82711;
    font-size: 12px;
    margin-top: -8px;
    max-width: inherit;
    margin-left: 5px;
    padding: 0 5px;
    position: absolute;
    top: 48px; }
  .field__save {
    margin-left: 20px; }
  .field > svg {
    fill: #68bab8;
    height: 24px;
    position: relative;
    width: 24px;
    margin-left: 5px; }
    .field > svg > use {
      height: 100%;
      width: 100%; }
  .field__selecttime {
    align-items: center;
    border: solid 2px #68bab8;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 120px; }
  .field--focus > svg {
    fill: #f9a94b; }
  .field--error {
    border-color: #E82711; }
    .field--error > svg {
      fill: #E82711; }
  .field__colon {
    font-size: 24px;
    height: 30px;
    line-height: 30px;
    position: relative;
    top: -1px; }
  .field__toggles {
    display: flex;
    width: 100%; }
  .field__toggle {
    border: none;
    margin-top: 20px;
    height: auto;
    max-height: none;
    min-height: none; }
    .field__toggle .field__label {
      left: 5px;
      top: -22px; }
    .field__toggle .field__wrap {
      flex: none;
      flex-direction: column;
      width: auto;
      height: auto; }
  .field__toggle-btn {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-right: 16px;
    width: 36px;
    height: 36px;
    padding: 0; }
    .field__toggle-btn > svg {
      display: none;
      fill: #fff;
      width: 28px;
      height: 28px; }
    .field__toggle-btn--active {
      box-shadow: none; }
      .field__toggle-btn--active > svg {
        display: block; }
    .field__toggle-btn:last-child {
      margin-right: 0; }

.field__style--filter .field {
  border: solid 1px #b3a2a1; }

.field__style--short .field {
  height: 40px;
  min-height: 40px;
  max-height: 40px; }
  .field__style--short .field__wrap {
    height: 36px; }
  .field__style--short .field__input {
    height: 34px;
    font-size: 16px; }
  .field__style--short .field__label {
    font-size: 13px;
    top: 11px; }

.select__wrap.field__style--filter::after {
  color: #b3a2a1; }

.field__selecttime .field {
  text-align-last: center;
  padding-left: 2px;
  padding-right: 2px; }

.fieldfile__input {
  cursor: pointer;
  opacity: 0; }

.fieldfile__view, .fieldfile__preview {
  align-items: center;
  display: flex;
  height: 120px;
  position: relative;
  width: 120px; }

.fieldfile__preview {
  flex-direction: column;
  position: relative; }

.fieldfile__view--large, .fieldfile__preview--large {
  height: 300px;
  width: 300px; }

.fieldfile__lable {
  display: block;
  left: 0;
  position: absolute;
  text-align: center;
  top: -20px;
  width: 100%;
  z-index: 0; }

.fieldfile__img, .fieldfile__img--round {
  background-position: center top;
  background-size: cover;
  height: inherit;
  width: inherit; }

.fieldfile__img--round {
  border-radius: 50%; }

.fieldfile__edit {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  background-color: #fff;
  color: #565353;
  position: absolute;
  right: -55px; }
  .fieldfile__edit:hover, .fieldfile__edit:focus {
    background-color: #efefef; }

.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-color: #fff;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  display: block;
  font-size: 16px;
  line-height: 1.3;
  margin: 0;
  max-width: 100%;
  padding: .6em 1.4em .5em .8em;
  position: relative;
  width: 100%; }
  .select::-ms-expand {
    display: none; }
  .select option {
    font-weight: normal; }
  .select:focus {
    outline: none; }
  .select__wrap {
    max-width: 200px; }
  .select__wrap::after {
    content: '\203A';
    color: #68bab8;
    pointer-events: none;
    font-size: 26px;
    font-weight: 600;
    position: absolute;
    transform: rotate(90deg);
    top: 10px;
    right: 13px;
    z-index: 1; }
  .select__wrap--nocaret::after {
    content: '';
    color: none;
    pointer-events: none;
    font-size: 26px;
    font-weight: 600;
    position: relative;
    transform: none;
    top: 0;
    right: 0;
    z-index: 0; }

.field__selecttime .select {
  padding-left: 2px;
  padding-right: 2px; }

.selecttime__row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-right: 24px;
  position: relative; }

.form__row--flexstart .selecttime__row {
  margin-bottom: 24px; }
  @media (min-width: 576px) {
    .form__row--flexstart .selecttime__row {
      margin-bottom: 0; } }

.flavor__h1 {
  margin-bottom: 24px; }

.flavor__feed, .flavor__searchmeta {
  max-width: 576px;
  width: 100%; }

.flavor__input {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  width: 100%; }

.flavor__ingr-meta {
  display: flex;
  flex-direction: column; }
  @media (min-width: 576px) {
    .flavor__ingr-meta {
      flex-direction: row; } }

.flavor__tags {
  margin-bottom: 16px; }
  @media (min-width: 576px) {
    .flavor__tags {
      margin-top: 32px;
      margin-right: 24px;
      padding-top: 4px; } }
  .flavor__tags > .chip {
    margin-right: 4px;
    margin-bottom: 4px; }
    @media (min-width: 576px) {
      .flavor__tags > .chip {
        margin-right: 0; } }

.flavor__description {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 5px;
  margin-bottom: 24px; }
  .flavor__description > .expander__wrap {
    max-height: 108px; }
  .flavor__description p {
    margin: 8px; }

.flavor__dcrp-src {
  display: flex;
  align-items: flex-start;
  bottom: 0;
  right: 0;
  position: absolute;
  padding-left: 30px;
  height: 25px;
  background-image: linear-gradient(to left, white 80%, rgba(255, 255, 255, 0.2)); }
  .flavor__dcrp-src > a {
    margin-right: 16px; }

.flavor__pairing {
  align-items: center;
  display: flex;
  justify-content: space-between;
  position: relative;
  width: 100%; }
  .flavor__pairing .search__icon {
    padding: 10px;
    width: 50px;
    height: 50px; }
    .flavor__pairing .search__icon svg {
      fill: #9F8B8A; }

.flavor__p-item {
  color: #444;
  position: relative;
  padding: 24px 15px 24px 8px; }
  .flavor__p-item:after {
    content: '\26B2';
    position: relative;
    left: 7px;
    top: 1px;
    display: inline-block;
    transform: rotate(-45deg); }
  .flavor__p-item:hover {
    background-color: #eee; }

.flavor__p-item {
  font-size: 17px; }

.flavor__p-item-rs {
  width: 24px;
  height: 24px;
  position: relative;
  top: 2px; }
  .flavor__p-item-rs > svg {
    fill: #9F8B8A;
    position: relative;
    width: inherit;
    height: inherit; }

.flavor__p-affinity-wrap {
  background-color: #e6e1e0;
  border-radius: 10px;
  bottom: 0;
  position: absolute;
  width: 100%;
  height: 7px;
  overflow: hidden; }

.flavor__p-affinity {
  height: 7px; }

.flavor__rs-title {
  margin-top: 0;
  margin-bottom: 40px;
  width: 100%; }

.flavor__aff-sl {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  margin-top: 6px;
  padding: 0 2px 0 5px;
  width: 100%; }
  .flavor__aff-sl > div {
    color: #b3a2a1; }

.flavor__aff-2 {
  background-color: #7ddcb9; }
  .flavor__aff-2:hover {
    background-color: #9ee5cb; }
  .flavor__aff-2:focus {
    background-color: #7ddcb9; }

.flavor__aff-3 {
  background-color: #3cca96; }
  .flavor__aff-3:hover {
    background-color: #6dd7b0; }
  .flavor__aff-3:focus {
    background-color: #3cca96; }

.flavor__aff-4 {
  background-color: #10ae74; }
  .flavor__aff-4:hover {
    background-color: #23eba2; }
  .flavor__aff-4:focus {
    background-color: #10ae74; }

.flavor__aff-5 {
  background-color: #0b7a51; }
  .flavor__aff-5:hover {
    background-color: #13d08a; }
  .flavor__aff-5:focus {
    background-color: #0b7a51; }

.flavor__sample {
  display: flex;
  flex-direction: row;
  max-width: 576px;
  flex-wrap: wrap;
  padding-top: 24px;
  justify-content: space-between; }
  .flavor__sample > a {
    margin: 0 4px 16px 4px; }

.flavor__affin-wrap {
  margin: 16px 0 32px 0;
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column; }
  .flavor__affin-wrap > h3 {
    margin-top: 0px; }

.flavor__affin-row {
  display: flex;
  flex-direction: row;
  margin-top: 16px; }

.flavor__affin {
  margin-right: 8px;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 25%; }
  .flavor__affin:before {
    content: '&';
    font-size: 12px;
    margin-right: 8px;
    position: relative; }

.flavor__affin:first-of-type:before {
  content: none;
  margin-left: 0; }

.flavor__affin-icon {
  height: 20px;
  width: 20px;
  position: relative;
  top: 0px; }

@media (min-width: 576px) {
  .flavor__lp {
    max-width: 768px; } }

.flavor__lp-section,
.flavor__lp .parsed-content {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  align-items: center; }

.flavor__lp img {
  width: 100%;
  max-width: 300px; }

.flavor__lp h3 {
  font-size: 24px;
  font-weight: 600; }

.flavor__lp h1, .flavor__lp h2, .flavor__lp h3, .flavor__lp img {
  margin: 24px 0; }

.flavor__lp p {
  margin: 16px; }

@media (min-width: 768px) {
  .flavor__lp-section {
    flex-direction: row; }
    .flavor__lp-section > img {
      margin-right: 24px; } }

@media (min-width: 768px) {
  .flavor__lp-section-2 > img {
    margin-left: 24px;
    min-width: 250px; } }

.flavor__lp-search {
  padding-top: 24px; }

.flavor__lp-img-rnd {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  border-radius: 10px; }

.flavor__lp-head {
  display: flex;
  flex-direction: column;
  align-items: center; }
  @media (min-width: 576px) {
    .flavor__lp-head {
      flex-direction: row; } }
  .flavor__lp-head > h1 {
    font-size: 32px;
    font-weight: 600; }
  .flavor__lp-head > img {
    width: 100%; }
    @media (min-width: 576px) {
      .flavor__lp-head > img {
        max-width: 200px; } }

.form.flavor__search {
  height: auto;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 576px;
  top: 0;
  justify-content: space-between; }
  .form.flavor__search > .form__row:nth-child(1) {
    order: 1; }
  .form.flavor__search > .form__row:nth-child(2) {
    order: 3; }
  .form.flavor__search > .form__row:nth-child(3) {
    order: 4; }
  .form.flavor__search > .form__row {
    margin: 0;
    margin-bottom: 24px; }
  .form.flavor__search > .form__row.flavor__toggle {
    margin-right: 24px; }
  @media (min-width: 576px) {
    .form.flavor__search {
      min-width: 400px; } }
  .form.flavor__search .form__row {
    width: auto; }
  .form.flavor__search .form__row.flavor__order {
    min-width: 150px; }
  .form.flavor__search .form__row.flavor__input {
    width: 100%; }

.flex__column {
  display: flex;
  flex-flow: column; }

.footer {
  border-top: solid 1px #f9b462;
  bottom: 0;
  display: flex;
  flex-direction: row;
  padding: 24px;
  position: relative; }
  @media print {
    .footer {
      display: none; } }
  .footer__linkgrp {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .footer__linkgrp a {
      font-size: 14px;
      margin-top: 8px; }
    .footer__linkgrp > p, .footer__linkgrp a {
      color: #565353; }
    .footer__linkgrp > p {
      font-size: 14px;
      margin-top: 8px;
      margin-bottom: 0; }
  .footer__cp {
    background-color: #565353;
    bottom: 0;
    display: flex;
    position: fixed; }
    .footer__cp > p {
      color: #fff;
      padding: 0 24px; }
      .footer__cp > p > a {
        color: #cbe3e2; }
        .footer__cp > p > a:hover {
          color: #68bab8; }
  .footer__cpbtn {
    margin: 16px;
    width: 150px; }
  @media (min-width: 576px) {
    .footer__linkgrp {
      width: 50%; } }
  @media (min-width: 768px) {
    .footer__linkgrp {
      width: 32%; } }

.form {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  max-width: 280px;
  position: relative;
  width: 100%; }
  .form__row, .form__field, .form__group {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    width: 100%; }
  .form__row {
    margin-top: 24px;
    justify-content: flex-start; }
  .form__group {
    flex-direction: column; }
  .form__row .form__row {
    margin-right: -16px;
    margin-left: -16px;
    margin-top: 0;
    justify-content: space-between; }
  .form__row .form__row--flexstart {
    margin-right: 0;
    margin-left: 0;
    flex-direction: column;
    align-items: flex-start; }
    @media (min-width: 576px) {
      .form__row .form__row--flexstart {
        align-items: center;
        flex-direction: row;
        justify-content: flex-start; } }
  .form__row .form__row > .form__field {
    margin-right: 16px;
    margin-left: 8px; }
  .form__info {
    padding-right: 24px;
    width: 100%;
    max-width: 175px; }
    @media (min-width: 576px) {
      .form__info {
        max-width: 200px; } }
    @media (min-width: 768px) {
      .form__info {
        max-width: 250px; } }
    @media (min-width: 992px) {
      .form__info {
        max-width: 300px; } }
  .form__infotext {
    margin: 0; }
  .form__separater {
    border-top: solid 1px #68bab8;
    height: 0;
    margin-top: 24px;
    width: 100%; }
  .form--error {
    color: #E82711;
    margin: 0;
    margin-top: 24px;
    text-align: center;
    width: 100%; }
  .form--md {
    max-width: 380px; }
  .form--xl {
    max-width: 100%; }
  .form__btns {
    align-items: center;
    display: flex;
    justify-content: center;
    margin-top: 24px;
    width: 100%; }
  .form__btngroup {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    width: 50%; }
  .form__btngroup:last-child {
    justify-content: flex-end; }
  .form__btngroup:only-child {
    justify-content: center;
    width: 100%; }
  .form__btnprescript {
    font-size: 14px;
    margin: 0;
    margin-top: 24px;
    text-align: center; }
  @media (min-width: 576px) {
    .form--horizontal {
      align-items: flex-end;
      flex-direction: row;
      max-width: none;
      justify-content: flex-start;
      position: relative;
      width: 100%;
      height: 72px;
      top: -24px; }
      .form--horizontal .form__row {
        margin-right: 16px;
        width: auto; }
      .form--horizontal .form__btns {
        justify-content: flex-start;
        height: 100%;
        margin: 0;
        position: relative;
        bottom: -12px;
        width: auto; }
      .form--horizontal .form__btngroup {
        margin-right: 16px;
        position: relative; }
        .form--horizontal .form__btngroup > .btn__lg {
          font-size: 16px;
          padding: 7px 20px 9px 20px; }
      .form--horizontal .form__row,
      .form--horizontal .form__field,
      .form--horizontal .form__group {
        width: 100%; } }

.form.gutters-large > .form__row {
  margin-top: 32px; }

.form.gutters-small > .form__row {
  margin-top: 24px; }

.form--xl .form.gutters-large > .form__row {
  margin-top: 32px; }

.home {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .home__content, .home__recipes {
    width: 100%; }

.icon {
  fill: #9F8B8A; }
  .icon--sm {
    width: 24px;
    height: 24px; }
  .icon--md {
    width: 36px;
    height: 36px; }
  .icon--primary {
    fill: #F7941E; }
  .icon--secondary {
    fill: #00AAA6; }

.list {
  align-items: center;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%; }
  .list__info {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    margin-bottom: 24px;
    width: inherit; }
    .list__info > h4 {
      margin: 0; }
  .list__name {
    display: flex;
    justify-content: center;
    margin: 16px 0; }
    .list__name > h1 {
      margin: 0; }
  .list__icon {
    margin-left: 8px;
    position: relative;
    top: 3px; }
  .list__filters {
    align-items: flex-start;
    display: flex;
    margin-bottom: 24px;
    position: relative;
    width: 100%; }
    .list__filters .form__row {
      width: 200px; }
  .list__totop {
    padding: 14px;
    position: fixed;
    bottom: 24px;
    right: 24px;
    z-index: 1; }
    .list__totop .btn__icon {
      margin: 0; }

.menubuilder__form {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%; }

.modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%; }
  .modal__wrap {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
    align-items: center;
    background: #fff;
    border-radius: 16px;
    margin: 50px 0 0 0;
    max-width: 600px;
    height: auto;
    padding: 20px;
    position: relative;
    width: 90%;
    z-index: 6; }
  .modal__wrap--large {
    max-width: 992px; }
  .modal__wrap--xl {
    max-width: 1200px; }

div.profile {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  padding: 24px 16px;
  margin-bottom: 32px; }
  div.profile__user {
    align-items: center;
    display: flex;
    flex-direction: column; }
    @media (min-width: 768px) {
      div.profile__user {
        flex-direction: row; } }
  div.profile__avatar {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px; }
    @media (min-width: 768px) {
      div.profile__avatar {
        margin-bottom: 0; } }
  div.profile__meta {
    font-size: 12px; }
    div.profile__meta > p {
      margin: 8px 0; }
  div.profile__bio > p {
    margin: 0 24px; }
  div.profile__delete {
    align-items: center;
    border-top: solid 1px #e0dad8;
    display: flex;
    flex-direction: column;
    margin-top: 32px;
    width: 100%; }

h4.profile__h4 {
  width: 100%;
  margin-bottom: 16px; }

.rating__stars {
  background: #9acdcc;
  overflow: hidden;
  position: relative;
  width: 108px;
  height: 25px;
  z-index: 1; }
  .rating__stars > svg {
    float: left;
    position: relative;
    left: -1px;
    top: -1px;
    width: 110px;
    height: 27px; }
  .rating__stars--authed {
    cursor: pointer; }

.rating__stars-bg {
  background: #F7941E;
  height: inherit;
  position: absolute; }

.recipe__info {
  display: flex;
  justify-content: space-between;
  width: 100%; }

@media print {
  .recipe {
    max-width: 2000px;
    padding-top: 0; } }

.recipe__printcta {
  display: none;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 16px; }
  @media print {
    .recipe__printcta {
      display: block; } }

.recipe__ingredients {
  margin-top: 32px; }
  .recipe__ingredients > h3 {
    margin-top: 0; }

.recipe__preparation > h3 {
  margin-bottom: 16px; }

.recipe__name {
  margin-top: 0;
  margin-bottom: 16px; }
  @media print {
    .recipe__name {
      font-size: 18px;
      margin-top: 0; } }

.recipe__visual {
  display: none;
  flex-grow: 1;
  max-width: 400px;
  width: 100%; }
  .recipe__visual > .recipe__img {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
    border-radius: 8px;
    width: 100%; }

.recipe__meta {
  flex-grow: 2; }
  .recipe__meta > .recipe__img {
    margin-top: 16px;
    width: 100%; }

.recipe__tags {
  margin-bottom: 24px; }
  @media print {
    .recipe__tags {
      display: none; } }

.recipe__actions {
  display: flex;
  flex-wrap: wrap; }
  .recipe__actions > button,
  .recipe__actions a[role=button] {
    margin: 0 8px 8px 0;
    flex-shrink: 0; }

.recipe__feedback {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between; }

.recipe__ratingtext {
  font-size: 14px;
  margin-right: 8px; }

.recipe__rating {
  background: #9acdcc;
  overflow: hidden;
  position: relative;
  width: 108px;
  height: 25px; }
  .recipe__rating > svg {
    float: left;
    position: relative;
    left: -1px;
    top: -1px;
    width: 110px;
    height: 27px; }

.recipe__rating-bg {
  background: #F7941E;
  height: inherit;
  position: absolute; }

.recipe__attrs {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative; }

.recipe__print {
  margin-top: 24px; }

.recipe__time {
  display: flex;
  margin-top: 24px; }
  .recipe__time > p {
    margin: 0 24px 0 0; }
    .recipe__time > p > span {
      font-weight: 700; }

.recipe__srvsys {
  display: flex;
  flex-direction: row; }

.recipe__scale {
  align-items: center;
  display: flex;
  margin-top: 24px;
  margin-right: 16px;
  width: 110px; }

.recipe__steps {
  list-style: none;
  counter-reset: recipe-step-counter;
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0; }
  .recipe__steps > li {
    counter-increment: recipe-step-counter;
    margin-bottom: 16px;
    position: relative; }
  .recipe__steps > li::before {
    color: #F7941E;
    content: counter(recipe-step-counter);
    font-family: Lobster, serif;
    font-size: 24px;
    margin-right: 8px;
    position: relative;
    top: 3px; }

.recipe__preparation, .recipe__notes {
  max-width: 560px; }

.recipe__comments {
  margin-top: 32px;
  position: relative; }
  @media print {
    .recipe__comments {
      display: none; } }

.recipe--embed .recipe__name {
  font-size: 18px;
  margin-bottom: 0; }

.recipe--embed .recipe__ingredients > p {
  margin: 8px 0; }

.recipe--embed .recipe__steps > li::before {
  color: #444; }

.recipe__embed > hr {
  border: none;
  border-top: solid 1px #ccc;
  color: #ccc;
  margin-top: 0;
  margin-bottom: 16px; }

.recipe__embedcode {
  display: flex;
  align-items: center;
  width: 100%; }
  .recipe__embedcode > button {
    flex-shrink: 0;
    margin-left: 16px; }
  .recipe__embedcode textarea {
    font-size: 14px; }

.recipe__content {
  display: flex;
  flex-direction: column; }
  .recipe__content > .recipe__ad {
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 24px 0;
    width: 100%; }
    .recipe__content > .recipe__ad > .recipe__ad-zone {
      position: relative;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 24px; }
      .recipe__content > .recipe__ad > .recipe__ad-zone > .ad {
        position: relative;
        width: 100%; }
    .recipe__content > .recipe__ad > .ad-2 {
      display: none; }
  @media (min-width: 768px) {
    .recipe__content {
      flex-direction: row; }
      .recipe__content > .recipe__detail {
        width: 100%;
        max-width: 576px; }
      .recipe__content > .recipe__ad {
        position: relative;
        order: 2;
        margin-left: 24px;
        width: 50%; }
        .recipe__content > .recipe__ad > .ad-2 {
          display: flex; } }

@media (min-width: 768px) {
  .recipe__info {
    margin-bottom: 24px; }
  .recipe__feedback {
    margin-bottom: 16px;
    padding-right: 24px; }
  .recipe__meta {
    max-width: 60%;
    padding-right: 24px; }
    .recipe__meta > .recipe__img {
      display: none; }
  .recipe__visual {
    display: block; }
  .recipe__userchip {
    margin-bottom: 0; }
  .recipe__attrs {
    align-items: center;
    display: flex;
    flex-direction: row; }
  .recipe__comments {
    max-width: 400px; }
  .recipe--embed .recipe__print {
    margin: 0;
    top: 12px; } }

@media print {
  .recipe__actions {
    display: none; }
  .recipe .recipe__img {
    float: right;
    display: block;
    margin-left: 8px;
    width: 200px; }
  .recipe h3 {
    font-size: 14px;
    margin-bottom: 12px; }
  .recipe p {
    margin: 6px 0;
    font-size: 12px; }
  .recipe li {
    font-size: 12px;
    margin-bottom: 12px; }
  .recipe li::before {
    font-size: 12px;
    top: 0; } }

.expander__content > .chip {
  margin: 0 5px 5px 0; }

.builder {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 900px; }
  .builder__header {
    display: flex;
    flex-direction: column;
    width: 100%; }
    @media (min-width: 768px) {
      .builder__header {
        align-items: center;
        flex-direction: row;
        justify-content: space-between; } }
    .builder__header > h1 {
      width: 100%; }
  .builder__help {
    max-width: 400px; }
  .builder__img-btn {
    margin-top: 24px; }

.info__btns > button {
  margin-right: 8px; }

.topnav {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
  align-items: center;
  background-color: #565353;
  display: flex;
  flex-shrink: 0;
  height: 75px;
  justify-content: space-between;
  padding: 0 24px;
  position: relative;
  width: 100%; }
  @media print {
    .topnav {
      display: none; } }
  .topnav__group {
    display: flex;
    height: 75px;
    position: relative; }
  .topnav__group--middle {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-color: #00AAA6;
    display: none;
    flex-direction: column;
    height: auto;
    position: absolute;
    top: 75px;
    width: 100%;
    left: 0;
    z-index: 3; }
    @media (min-width: 768px) {
      .topnav__group--middle {
        background-color: transparent;
        box-shadow: none;
        display: flex;
        flex-direction: row;
        flex-grow: 3;
        justify-content: flex-end;
        position: relative;
        top: 0;
        width: auto; } }
    .topnav__group--middleopen {
      display: flex; }
    @media (min-width: 768px) {
      .topnav__group--middle > .topnav__user {
        display: none; } }
  @media (min-width: 768px) {
    .topnav__search {
      margin-right: 8px;
      margin-top: 15px; } }
  @media (min-width: 992px) {
    .topnav__search {
      margin-right: 16px;
      margin-top: 15px; } }
  .topnav__group--end {
    flex-direction: row-reverse; }
    @media (min-width: 768px) {
      .topnav__group--end {
        min-width: 90px; } }
    .topnav__group--end > .topnav__user {
      display: none; }
      @media (min-width: 768px) {
        .topnav__group--end > .topnav__user {
          align-items: center;
          display: flex;
          flex-direction: column;
          position: relative;
          right: -24px;
          height: 75px;
          top: 0; } }
  .topnav__hamburger {
    width: 50px;
    height: 75px;
    padding: 0; }
    @media (min-width: 768px) {
      .topnav__hamburger {
        display: none; } }
    .topnav__hamburger > svg {
      fill: #fff;
      height: inherit;
      width: inherit;
      position: relative; }
  .topnav__link {
    border-top: solid 1px #68bab8;
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 50px;
    position: relative;
    width: 100%; }
    @media (min-width: 768px) {
      .topnav__link {
        border-top: none;
        height: 75px;
        width: auto; } }
    .topnav__link:hover {
      background-color: #4d4b4b; }
      .topnav__link:hover > .topnav__tab {
        color: #F7941E; }
    .topnav__link:focus > .topnav__tab,
    .topnav__link.active > .topnav__tab {
      background-color: #4d4b4b;
      color: #F7941E; }
      @media (min-width: 768px) {
        .topnav__link:focus > .topnav__tab,
        .topnav__link.active > .topnav__tab {
          background-color: transparent; } }
  .topnav__link > .topnav__tab {
    color: #fff;
    display: flex;
    flex-direction: column;
    font-size: 16px;
    height: inherit;
    justify-content: center;
    margin: 0;
    padding: 0 16px;
    position: relative;
    width: inherit; }
    @media (min-width: 768px) {
      .topnav__link > .topnav__tab {
        padding: 0 8px; } }
    @media (min-width: 992px) {
      .topnav__link > .topnav__tab {
        padding: 0 16px; } }
  .topnav__group--start .topnav__link {
    border: none; }
  @media (min-width: 768px) {
    .topnav__group--start {
      width: 150px; } }
  @media (min-width: 992px) {
    .topnav__group--start {
      width: 200px; } }
  .topnav__logo {
    display: flex;
    height: 75px;
    left: -24px;
    padding: 0 16px;
    position: relative;
    line-height: 75px;
    width: 180px; }
    .topnav__logo .logo {
      color: #fff;
      position: absolute;
      left: 75px;
      font-size: 20px;
      margin: 0; }
    .topnav__logo > svg {
      flex-shrink: 0;
      fill: #fff;
      width: 55px;
      height: 55px;
      position: relative;
      left: -5px; }
  .topnav__active {
    border-bottom: solid 5px #F7941E;
    bottom: 0;
    display: none;
    height: 5px;
    position: absolute;
    left: 0;
    width: 50px;
    z-index: 4; }
  .topnav__signin {
    background: transparent;
    box-shadow: none;
    color: #fff;
    height: 50px;
    padding-left: 16px;
    text-align: left;
    width: 100%; }
    .topnav__signin:active, .topnav__signin:focus, .topnav__signin:hover {
      background: transparent; }
    @media (min-width: 768px) {
      .topnav__signin {
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
        transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
        background-color: #F7941E;
        position: relative;
        right: 24px;
        top: 12px; }
        .topnav__signin:active, .topnav__signin:focus, .topnav__signin:hover {
          background: #f9a94b; } }

.search {
  position: relative;
  width: 100%;
  z-index: 1; }
  @media (min-width: 992px) {
    .search {
      max-width: 500px; } }
  .search__advanced {
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-color: #565353;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    display: none;
    flex-direction: column;
    padding: 16px 16px 8px 16px;
    position: absolute;
    width: 100%; }
    @media (min-width: 768px) {
      .search__advanced {
        min-width: 400px; } }
  .search__advanced--show {
    display: flex; }
  .search__bar {
    background-color: #fff;
    display: flex;
    overflow: hidden; }
    @media (min-width: 768px) {
      .search__bar {
        border-radius: 5px; } }
    .search__bar > form {
      display: flex;
      height: 46px;
      width: 100%; }
      .search__bar > form > .search__icon {
        width: 70px; }
        .search__bar > form > .search__icon > svg {
          fill: #444; }
    .search__bar > .search__expand,
    .search__bar > .search__icon {
      border-radius: 0;
      background-color: transparent;
      flex-shrink: 0; }
      .search__bar > .search__expand:hover, .search__bar > .search__expand:focus,
      .search__bar > .search__icon:hover,
      .search__bar > .search__icon:focus {
        background-color: #eee; }
    .search__bar > .search__expand {
      width: 40px; }
      .search__bar > .search__expand:before {
        color: #444;
        content: '\203A';
        font-size: 30px;
        transform: rotate(90deg);
        position: absolute;
        top: 4px;
        left: 20px; }
    .search__bar > .search__expand--up:before {
      transform: rotate(-90deg);
      left: 12px; }
    .search__bar > .search__expand--hladv {
      background-color: #F7941E; }
      .search__bar > .search__expand--hladv:before {
        color: #fff; }
  .search__field {
    border-radius: 5px;
    border: solid 2px #68bab8;
    display: flex;
    position: relative; }
  .search__input {
    display: flex;
    position: relative;
    width: 100%; }
    .search__input > .search__icon {
      width: auto; }
      .search__input > .search__icon > svg {
        fill: #444; }
  .search__bar--atcplt {
    flex-direction: column;
    position: relative;
    overflow: visible; }
  .search__atcplt-item {
    color: #777;
    padding: 8px 16px; }
    .search__atcplt-item--focus {
      background-color: #e0dad8;
      color: #444; }
  .search__autocomplete {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    transition: all 0.1s cubic-bezier(0.25, 0.8, 0.25, 1);
    background-color: #fff;
    border: solid 2px #68bab8;
    border-width: 1px;
    border-top: none;
    border-radius: 5px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    padding: 8px 0;
    position: absolute;
    top: 47px;
    width: 100%;
    z-index: 3; }
  .search__form {
    border-bottom: solid 1px #9F8B8A;
    display: flex;
    margin-bottom: 8px;
    padding-bottom: 8px; }
  .search__ingredients {
    display: flex;
    flex-direction: column; }
  .search__tags {
    border-left: solid 1px #9F8B8A;
    display: flex;
    flex-direction: column;
    margin-left: 8px;
    padding-left: 8px;
    max-width: 220px; }
  .search__i {
    display: flex; }
  .search__i--include {
    border-bottom: solid 1px #9F8B8A;
    margin-bottom: 4px;
    padding-bottom: 8px; }
  .search__t {
    width: 100%; }
    .search__t:nth-of-type(1) {
      margin-bottom: 16px; }
    .search__t textarea {
      width: 100%; }
    .search__t .field__label {
      font-size: 13px; }
  .search__wrap-i {
    align-items: center;
    display: flex;
    margin-top: 4px;
    height: 40px; }
    .search__wrap-i .field {
      border-width: 1px; }
  .search__i-del {
    height: 32px;
    margin-left: 8px;
    padding: 3px 0 0 0;
    width: 32px; }
    .search__i-del > svg {
      fill: #fff;
      height: 22px;
      width: 22px; }
  .search__i-add-wrap {
    height: inherit;
    display: flex;
    align-items: center;
    margin-left: 8px;
    position: relative;
    padding-right: 32px; }
  .search__i-add {
    height: 32px;
    width: 32px;
    padding: 2px 0 0 0;
    position: absolute;
    bottom: 4px; }
    .search__i-add > svg {
      fill: #fff;
      height: 28px;
      width: 28px; }
  .search__info {
    color: #fff;
    font-size: 12px;
    line-height: 16px;
    margin: 4px 0 8px 0; }
  .search__form-btns {
    display: flex;
    justify-content: flex-end; }
    .search__form-btns > button {
      padding-top: 5px;
      padding-bottom: 5px; }
    .search__form-btns > button:nth-child(1) {
      margin-right: auto; }
    .search__form-btns > button:nth-child(2) {
      margin-right: 16px; }
  .search__icon {
    display: flex;
    padding: 6px 12px 4px 12px; }
    .search__icon > svg {
      fill: #fff;
      height: 36px;
      width: 36px; }

.share {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%; }
  .share__icons {
    display: flex;
    justify-items: center;
    margin: 24px 0; }
  .share__copy {
    display: flex;
    justify-content: center;
    margin-top: 8px; }
  .share__site {
    width: 36px;
    height: 36px;
    padding: 0;
    margin-right: 16px; }
    .share__site:nth-last-child(1) {
      margin-right: 0; }
    .share__site > svg {
      width: inherit;
      height: inherit; }

.usermenu {
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: relative;
  width: inherit; }
  @media (min-width: 768px) {
    .usermenu {
      max-width: 198px;
      min-width: 175px;
      right: 0;
      top: 0;
      z-index: 1; } }
  .usermenu__wrap {
    position: relative;
    margin: 0;
    padding: 0;
    width: 100%; }
  .usermenu--options {
    background-color: #00AAA6;
    color: #fff;
    overflow: hidden; }
  .usermenu div.img--round.usermenu__avatar {
    flex-shrink: 0;
    margin-right: 10px;
    height: 40px;
    width: 40px; }
    @media (min-width: 768px) {
      .usermenu div.img--round.usermenu__avatar {
        height: 50px;
        width: 50px; } }
  .usermenu__name {
    color: #fff;
    max-width: 100px;
    padding-right: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; }
  .usermenu--options .usermenu__name {
    color: #fff; }
  .usermenu--options .usermenu__item {
    color: #fff; }
  .usermenu__svgwrap {
    border: solid 1.5px #00AAA6;
    border-radius: 50%;
    overflow: hidden;
    position: relative; }
  .usermenu__svg {
    bottom: -100px;
    fill: #00AAA6;
    height: 120%;
    left: -100px;
    margin: auto;
    position: absolute;
    right: -100px;
    top: -90px;
    width: 120%; }
  .usermenu__top {
    align-items: center;
    display: flex;
    flex-shrink: 0;
    height: 50px;
    padding-left: 16px;
    position: relative; }
    @media (min-width: 768px) {
      .usermenu__top {
        height: 75px; } }
  .usermenu__options {
    display: none; }
  .usermenu__options--show {
    display: flex;
    flex-direction: column; }
  .usermenu__item {
    border-top: solid 1px #68bab8;
    height: 50px;
    line-height: 50px;
    padding: 0 0 0 32px;
    width: 100%;
    text-align: left; }
  .usermenu button.usermenu__item,
  .usermenu a.usermenu__item {
    border-radius: 0;
    background-color: #00AAA6; }
    .usermenu button.usermenu__item:hover,
    .usermenu a.usermenu__item:hover {
      background-color: #68bab8; }
    .usermenu button.usermenu__item.active,
    .usermenu a.usermenu__item.active {
      background-color: #4d4b4b;
      color: #F7941E; }

.viewblocker {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  position: absolute;
  width: 100%; }
  .viewblocker__blocker {
    background: rgba(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 5; }
  .viewblocker__children {
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 100%;
    z-index: 6; }

.viewblocker.appwaiting {
  z-index: 10; }
