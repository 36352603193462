
@mixin fieldfile(
    $name: 'fieldfile'
) {
    .#{$name} {
        
        &__input {
            cursor: pointer;
            opacity: 0;
        }

        &__view,
        &__preview {
            align-items: center;
            display: flex;
            height: 120px;
            position: relative;
            width: 120px;
        }
        
        &__preview {
            flex-direction: column;
            position: relative;
        }
        
        &__view--large,
        &__preview--large {
            height: 300px;
            width: 300px;
        }

        &__lable {
            display: block;
            left: 0;
            position: absolute;
            text-align: center;
            top: -20px;
            width: 100%;
            z-index: 0;
        }
        
        &__img,
        &__img--round {
            background-position: center top;
            background-size: cover;
            height: inherit;
            width: inherit;
        }
        
        &__img--round {
            border-radius: 50%;
        }

        &__edit {
            @include boxshadow(2);

            background-color: #fff;
            color: $quaternary-color;
            position: absolute;
            right: -55px;

            &:hover,
            &:focus {
                background-color: #efefef;
            }
        }
    }
}